export const MAP_CENTER = [0, 0];

export const MAP_DEFAULT_ZOOM = 2.1;
export const MAP_MIN_ZOOM = 2;

export const MAP_VIEWS = [
    {
        name: 'Falt Earth View',
        dimension: 'mercator',
    },
    {
        name: '3D Globe View',
        dimension: 'globe',
    },
];

export const MAP_STYLES = [
    {
        name: 'Mapbox Dark',
        style: 'dark-v10',
    },
    {
        name: 'Mapbox Light',
        style: 'light-v10',
    },
    {
        name: 'Mapbox Satellite',
        style: 'satellite-v9'
    },
];

export const GEODATA_OPTIONS = [
    {
        name: 'Orders',
        type: 'orders',
    },
    {
        name: 'Leagacy Data',
        type: 'legacyData',
    },
]