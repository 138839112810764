export const LatLongSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
        <path d="M6.5 12C9.8084 12 12.5 9.3084 12.5 6C12.5 2.6916 9.8084 0 6.5 0C3.1916 0 0.5 2.6916 0.5 6C0.5 9.3084 3.1916 12 6.5 12ZM6.6876 11.6144V0.3856C8.3064 0.5624 9.594 3.0128 9.594 6C9.594 8.9872 8.3064 11.4376 6.6876 11.6144ZM6.3124 0.3856V11.6144C5.5708 11.5336 4.8732 10.974 4.3304 10.0176C3.7232 8.9476 3.3884 7.5208 3.3884 6C3.3884 4.4792 3.7228 3.0524 4.3304 1.9824C4.8732 1.026 5.5712 0.4668 6.3124 0.3856ZM12.1252 6C12.1252 8.5808 10.378 10.7608 8.0048 11.4204C8.3676 11.122 8.7 10.7124 8.9876 10.202C9.6204 9.0784 9.9692 7.586 9.9692 6C9.9692 4.414 9.6208 2.9216 8.9876 1.798C8.7 1.2876 8.368 0.878 8.0048 0.5796C10.378 1.2392 12.1252 3.4192 12.1252 6ZM4.99 0.5812C4.6264 0.8796 4.2932 1.288 4.004 1.7976C3.3652 2.9232 3.0132 4.4156 3.0132 6.0004C3.0132 7.5852 3.3652 9.0776 4.004 10.2032C4.2932 10.7124 4.6264 11.1212 4.99 11.4196C2.6192 10.758 0.8748 8.5796 0.8748 6.0008C0.8752 3.4212 2.6192 1.2424 4.99 0.5812Z" fill="#F0F0F0" />
        <path d="M6.5 0C3.1916 0 0.5 2.6916 0.5 6C0.5 9.3084 3.1916 12 6.5 12C9.8084 12 12.5 9.3084 12.5 6C12.5 2.6916 9.8084 0 6.5 0ZM9.304 1.1252H3.696C4.522 0.6484 5.4796 0.3752 6.5 0.3752C7.5204 0.3752 8.478 0.648 9.304 1.1252ZM3.1288 1.5H9.8712C10.5616 2.0188 11.1292 2.6924 11.5224 3.4688H1.4776C1.8708 2.6924 2.4384 2.0188 3.1288 1.5ZM11.6952 8.1564H1.3048C1.0508 7.5468 0.9012 6.8828 0.8784 6.1876H12.1216C12.0988 6.8828 11.9492 7.5464 11.6952 8.1564ZM0.8784 5.8124C0.9012 5.1172 1.0508 4.4536 1.3048 3.8436H11.6948C11.9488 4.4532 12.0984 5.1172 12.1212 5.8124H0.8784ZM3.696 10.8752H9.304C8.478 11.352 7.5204 11.6252 6.5 11.6252C5.4796 11.6252 4.522 11.352 3.696 10.8752ZM9.8712 10.5H3.1288C2.4384 9.9812 1.8708 9.3076 1.4776 8.5312H11.5224C11.1292 9.3076 10.5616 9.9812 9.8712 10.5Z" fill="#F0F0F0" />
    </svg>
)

export const AltitudeSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="4" height="12" viewBox="0 0 4 12" fill="none">
        <path d="M0.654297 1H2.00045M3.3466 1H2.00045M2.00045 1V11H0.654297H3.3466" stroke="#F0F0F0" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)

export const ElevationSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="8" viewBox="0 0 13 8" fill="none">
        <path d="M0.8125 6.75029H12.1857M0.8125 6.75029L6.3125 3.86918M0.8125 6.75029L11.3125 1.25L6.3125 3.86918M7.07337 6.75029C7.07337 5.96459 6.8125 4.5 6.3125 3.86918" stroke="#F0F0F0" stroke-width="0.521739" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)
