
export function calculate_frame_slots(simulation_start_time: number, simulation_end_time: number, frame_duration: number): number[][] {
    const frameTimes: number[][] = [];
    let currentTime = simulation_start_time;
    const endTime = simulation_end_time;
    const timeframe_duration = frame_duration;
    while (currentTime < endTime) {
        let frameEnd = currentTime + timeframe_duration;
        if (frameEnd > endTime) {
            frameEnd = endTime;
        }
        frameTimes.push([currentTime, frameEnd]);
        currentTime = frameEnd;
    }

    return frameTimes;
}

export const roundup_start_time = (start_time: number) => {
    // Convert epoch time to Date object
    const date = new Date(start_time * 1000);
    // Get minutes and round to nearest 15-minute interval
    const minutes = date.getMinutes();
    const roundedMinutes = 15 * Math.floor(minutes / 15);

    // Set rounded minutes and reset seconds and milliseconds
    date.setMinutes(roundedMinutes);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return Math.floor(date.getTime() / 1000);
}

export const roundup_end_time = (end_time: number): number => {
    // Convert epoch time to Date object
    const date = new Date(end_time * 1000);
    // Get minutes and round to nearest 15-minute interval
    const minutes = date.getMinutes();
    const roundedMinutes = (minutes > 0 && minutes < 15) ? 15 : (minutes > 15 && minutes < 30) ? 30 : (minutes > 30 && minutes < 45) ? 45 : 60;

    // Set rounded minutes and reset seconds and milliseconds
    date.setMinutes(roundedMinutes);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return Math.floor(date.getTime() / 1000);
};



export const get_current_slot = async (current_time, time_slots: number[][]) => {
    let current_slot = time_slots.find(slot => current_time >= slot[0] && current_time < slot[1]);
    return current_slot;
}