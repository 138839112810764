import { Fragment, useEffect, useRef, useState } from "react";
import './Schedule.scss'
import {
    CollapseSvg,
    GroundTaskSvg,
    OnBoardSvg,
    PayloadTaskSvg,
    PinSvg,
} from "../Svgs";
import Modal from "../Modal";
import useModals from "../../../../hooks/useModals";
import { useSearchParams } from "react-router-dom";
import useOnBoardSchedule, { ScheduleData } from "../../../../hooks/useOnBoardSchedule";
import CustomLoader from "../../../../custom_components/CustomLoader";
import CustomToolTip from "../../../../../CommonComponents/CustomToolTip";
import useContacts from "../../../../hooks/useContacts";
import useViewer from "../../../../hooks/useViewer";
import { get_formated_time, get_task_status_icon } from "../../../../utils";
import EmptyPage from "../../../../custom_components/EmptyPage";

const OnBoardschedule = () => {
    const [searchParams] = useSearchParams();
    const [modal, setModal] = useState({
        open: false,
        pinned: false,
        current_modal: ''
    })
    const { selectedScheduleTask } = useContacts()
    const { current_simulation_time } = useViewer()
    const { loading, scheduleData, select_schedule_data } = useOnBoardSchedule()
    const [expand, setExpand] = useState(false);
    const { handleUpdateUrl, getModalDetails } = useModals()

    const taskRef = useRef<any>(null);
    const containerRef = useRef<any>(null);

    useEffect(() => {
        const details = getModalDetails('onboard-schedule')
        setModal(details)
    }, [searchParams])

    const open_modal = (event: any) => {
        event.stopPropagation()
        if (modal.pinned) {
            handleUpdateUrl('onboard-schedule', 'pinned')
        } else {
            handleUpdateUrl('onboard-schedule', 'open')
        }
    }

    const pinned_modal = (event: any) => {
        event.stopPropagation()
        if (modal.pinned) {
            handleUpdateUrl('onboard-schedule', 'open')
        } else {
            handleUpdateUrl('onboard-schedule', 'pinned')
        }
    }
    const close_modal = (event: any) => {
        event.stopPropagation()
        handleUpdateUrl('onboard-schedule', 'close')
    }

    useEffect(() => {
        const close_modal = () => {
            const details = getModalDetails('onboard-schedule')
            if (details.open && !details.pinned) {
                handleUpdateUrl('onboard-schedule', 'close')
            }
        }
        window.addEventListener('click', close_modal)
        return () => {
            window.removeEventListener('click', close_modal)
        }
    }, [])

    useEffect(() => {
        if (!selectedScheduleTask.default_schedule) {
            const element = taskRef.current;
            const container = containerRef.current;

            if (element && container) {
                const { top: elementTop, bottom: elementBottom } = element.getBoundingClientRect();
                const { top: containerTop, bottom: containerBottom } = container.getBoundingClientRect();

                if (elementTop < containerTop || elementBottom > containerBottom) {
                    const scrollTop = element.offsetTop - container.offsetTop;
                    container.scrollTop = scrollTop
                    element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                }
            }
        }
        // Scroll the last task into view when the component mounts or tasks change

    }, [selectedScheduleTask]);


    return (
        <Modal
            open={modal.open}
            pinned={modal.current_modal === 'onboard-schedule' && modal.pinned}
            disabled={modal.current_modal === 'onboard-schedule' && modal.pinned}
            class_name={`${modal.current_modal === 'onboard-schedule' ? 'current__modal' : ''}`}
        >
            <div className={`schedule__panel__container ${expand && 'expand'} `} onClick={open_modal}>
                <div className="schedule__panel__header">
                    <div className=" d-flex align-items-center gap-2">
                        <OnBoardSvg />
                        <span className="panel__header__title">Onboard Schedule</span>
                    </div>
                    <div className=" d-flex align-items-center">
                        <button className="panel__colapse__btn" onClick={(event: any) => {
                            event.stopPropagation()
                            setExpand(!expand)
                        }}>
                            <CollapseSvg />
                        </button>
                        <button className={`panel__pin__btn ${modal.pinned ? 'pinned' : ''}`} onClick={pinned_modal}>
                            <PinSvg />
                        </button>
                        <button className={`panel__close__btn`} onClick={close_modal}>
                            <i className="fe fe-x fs-16" />
                        </button>
                    </div>
                </div>
                <div className="schedule__panel__body__container">
                    <CustomLoader loading={loading} />
                    {(!loading && scheduleData?.length > 0) &&
                        <Fragment>
                            <div className="panel__container__header">
                                <span className="container__header__item">Task</span>
                                <span className="container__header__item">Start Time</span>
                                <span className="container__header__item">Geo-trigger | Location</span>
                            </div>
                            <div className="panel__container__body" ref={containerRef}>
                                {scheduleData && scheduleData?.map((task: ScheduleData, index) => {
                                    const current_time = current_simulation_time / 1000
                                    const task_start_time = task.startTime;
                                    const task_end_time = task.endTime;
                                    const task_type_icon = task.type === 'Payload Task' ? <PayloadTaskSvg /> : <GroundTaskSvg />
                                    const task_status_icon = get_task_status_icon(task_start_time, task_end_time, current_time)
                                    const sub_name = task.type === 'Payload Task' ? `${task?.['properties']?.['payloadName']} | ${task?.['properties']?.['sequenceName']}` : `${task?.['properties']?.['groundStationName']}`
                                    // const task_running = task?.['status'] === 'active' ? 'running' : ''
                                    const task_running = (current_time >= task_start_time && current_time <= task_end_time) ? 'running' : ''
                                    const geo_trigger_location = task?.['location'] ? `${task?.['geoTrigger']} | ${task?.['location']}` : `${task?.['geoTrigger']}`

                                    return (
                                        <Fragment key={index}>
                                            <div
                                                ref={task?.['startTime'] === selectedScheduleTask?.schedule_data?.['startTime'] ? taskRef : null}
                                                className={`body__item__container ${!expand && 'cursor-auto'} ${(task?.['startTime'] === selectedScheduleTask?.schedule_data?.['startTime'] && expand) && 'active'}`}
                                                onClick={() => { select_schedule_data(task) }}
                                            >
                                                <div className={`item__details__header__container ${task_running}`}>
                                                    <div className="container__header__item">
                                                        <div className="icon__container">
                                                            {task_status_icon}
                                                            {task_type_icon}
                                                        </div>
                                                        <div className="details__container">
                                                            <span>
                                                                <CustomToolTip title={task?.['taskName']} placement="top">
                                                                    {task?.['taskName']}
                                                                </CustomToolTip>
                                                            </span>
                                                            <span>
                                                                <CustomToolTip title={sub_name} placement="top">
                                                                    {sub_name}
                                                                </CustomToolTip>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="container__header__item">
                                                        <span>{get_formated_time(task?.['startTime'])}</span>
                                                    </div>
                                                    <div className="container__header__item">
                                                        <span>
                                                            <CustomToolTip title={geo_trigger_location} placement="top">
                                                                {geo_trigger_location}
                                                            </CustomToolTip>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="item__details__body__container">
                                                    <div className="details__body__container__header">
                                                        <div className="details__body__container__header__item">SUBSYSTEM & COMMAND </div>
                                                        <div className="details__body__container__header__item">Start Time</div>
                                                        <div className="details__body__container__header__item">Duration</div>
                                                    </div>
                                                    <div className="details__body__container__body__container">
                                                        {task?.['commandsList'] && task?.['commandsList'].map((command, id) => {
                                                            const current_time = current_simulation_time / 1000
                                                            const sub_task_start_time = command.epoch;
                                                            const sub_task_end_time = command.epoch + command.duration;
                                                            const sub_task_status_icon = get_task_status_icon(sub_task_start_time, sub_task_end_time, current_time);
                                                            // const sub_task_running = command?.['status'] === 'active' ? 'running' : ''
                                                            const sub_task_running = (current_time >= sub_task_start_time && current_time <= sub_task_end_time) ? 'running' : ''
                                                            return (
                                                                <Fragment key={id}>
                                                                    <div className={`details__body__container__body ${sub_task_running}`}>
                                                                        <div className="details__body__container__body__item">
                                                                            <div className="icon__container">{sub_task_status_icon}</div>
                                                                            <div className="details__container">
                                                                                <span>
                                                                                    <CustomToolTip title={command?.['name']} placement="top">
                                                                                        {command?.['name']}
                                                                                    </CustomToolTip>
                                                                                </span>
                                                                                <span>
                                                                                    <CustomToolTip title={command?.['subsystem']} placement="top">
                                                                                        {command?.['subsystem']}
                                                                                    </CustomToolTip>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="details__body__container__body__item">
                                                                            <span>{get_formated_time(command?.['epoch'])}</span>
                                                                        </div>
                                                                        <div className="details__body__container__body__item">
                                                                            <span>{command?.['duration']} sec</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="details__body__container__body__container__separator" />
                                                                </Fragment>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="body__item__separator" />
                                        </Fragment>
                                    )
                                })}
                            </div>
                        </Fragment>
                    }
                    {scheduleData?.length === 0 && <EmptyPage content={expand ? 'Task Not Scheduled' : ''} />}
                </div>
            </div>
        </Modal>
    )
}

export default OnBoardschedule