// @ts-nocheck
import { lazy } from 'react';
import MissionDetails from './components/Missions/MissionDetails';
import PreviewPayloads from './components/Missions/StepperComponents/CommonSteperComponents/Payloads/PreviewPayload';
import EdgePreview from './components/Missions/StepperComponents/CommonSteperComponents/Edge/EdgePreview';
import Edge from './components/Missions/StepperComponents/CommonSteperComponents/Edge/Edge';
import SelectTemplate from './components/Missions/StepperComponents/CommonSteperComponents/Template/SelectTemplate';
import Payloads from './components/Missions/StepperComponents/CommonSteperComponents/Payloads/Payloads';
import ReviewBuild from './components/Missions/StepperComponents/CommonSteperComponents/ReviewBuild';
import Simulate from './components/Missions/Simulate/Simulate';
import Satellites from './components/Missions/Satellites/Satellites';
import Dashboard from './components/Missions/TrueTwins/TrueTwin_Dashboards/Dashboard';
import Bus from './components/Missions/StepperComponents/CommonSteperComponents/Bus/Bus';
import Operate from './components/Missions/Operate/Operate';
import User from './components/Missions/AdminPanel/User';
import NewScenarioFlow from './components/Missions/TrueTwins/Scenario/newScenarioFlow/NewScenarioFlow';
import MissionModeling from './components/MissionModeling/MissionModeling';
import SummeryDashBoard from './components/Missions/TrueTwins/Scenario/summeryDashBoard/SummeryDashBoard';
import ValidationOutPut from './components/Missions/TrueTwins/ValidationRun/ValidationOutPut';
import GroundStationsDetails from './components/Missions/GroundStationsAndProviders/GroundStationsDetails/GroundStationsDetails';
import GSProviderDetails from './components/Missions/GroundStationsAndProviders/GSProviderDetails/GSProviderDetails';
import CommunicationDetails from './components/Missions/Satellites/Communication/CommunicationDetails/CommunicationDetails';
import CommandCenterDashboard from './components/command_center/CommandCenterDashboard';
import DrawFeature from './components/Missions/Feature/DrawFeature';
import ShapeFileFeature from './components/Missions/Feature/ShapeFileUpload';
import MissionModelling from './components/MissionModelling/MissionModeling'
import UnifiedControlPlane from './components/UnifiedControlPlane/UnifiedControlPlane';
const Missions = lazy(() => import('./components/Missions/Missions'));
const MissionConfig = lazy(() => import('./components/Missions/MissionConfig'));
const SatelliteDetails = lazy(() => import('./components/Missions/Satellites/SatelliteDetails'));
const TrueTwinDetails = lazy(() => import('./components/Missions/TrueTwins/TrueTwinDetails'));
const PayloadDetails = lazy(() => import('./components/Missions/Satellites/PayloadDetails'));
const Catalog = lazy(() => import('./components/Missions/Satellites/Catalog'));

interface RoutesType {
  url: string,
  component: any,
  id: string,
}

const RouterUrl: RoutesType[] = [

  {
    url: '/user/:user_name/:user_id',
    component: User,
    id: 'user',
  },
  {
    url: '/',
    component: Missions,
    id: 'mission',
  },

  {
    url: '/:project_name/:project_id/create_new_mission',
    component: MissionConfig,
    id: 'create_new_mission',
  }, {
    url: '/:project_name/edit_mission/:mission_name/:mission_id',
    component: MissionConfig,
    id: 'edit_mission',
  },
  {
    url: '/:mission_name/_details/:mission_id/:tab_name',
    component: MissionDetails,
    id: 'mission_details',
  },


  {
    url: '/:mission_name/:mission_id/:sat_name/:sat_id/add_new_payload',
    component: Payloads,
    id: 'add_new_payload',
  }, {
    url: '/:mission_name/:mission_id/:sat_name/:sat_id/preview_payload',
    component: PreviewPayloads,
    id: 'preview_payload',
  }, {
    url: '/:mission_name/:mission_id/:sat_name/:sat_id/edit_payload/:payload_name/:payload_id',
    component: Payloads,
    id: 'edit_payload',
  },

  {
    url: '/:mission_name/:mission_id/:sat_name/:sat_id/add_new_edge',
    component: Edge,
    id: 'add_new_edge',
  }, {
    url: '/:mission_name/:mission_id/:sat_name/:sat_id/preview_edge',
    component: EdgePreview,
    id: 'preview_edge',
  }, {
    url: '/:mission_name/:mission_id/:sat_name/:sat_id/edit_edge/:edge_name/:edge_id',
    component: Edge,
    id: 'edit_edge',
  },

  {
    url: '/:mission_name/:mission_id/:sat_name/:sat_id/add_new_template',
    component: SelectTemplate,
    id: 'add_new_template',
  }, {
    url: '/:mission_name/:mission_id/:sat_name/:sat_id/edit_template/:template_name/:template_id',
    component: SelectTemplate,
    id: 'edit_template',
  },

  {
    url: '/:mission_name/:mission_id/:sat_name/:sat_id/review_satellite',
    component: ReviewBuild,
    id: 'review_satellite',
  },

  {
    url: '/:mission_name/:mission_id/view_satellite/:sat_name/:sat_id/:type',
    component: Bus,
    id: 'bus',
  },
  {
    url: '/:mission_name/:mission_id/:sat_name/:sat_id/:type/:bus_name/:bus_id',
    component: Bus,
    id: 'create_bus',
  },

  {
    url: '/:mission_name/:mission_id/satellites',
    component: Satellites,
    id: 'Satellites',
  },
  {
    url: '/:mission_name/:mission_id/sat_details/:sat_name/:sat_id/:tab_name',
    component: SatelliteDetails,
    id: 'satelliteDetails',
  },
  {
    url: '/:mission_name/:mission_id/:sat_name/:sat_id/template/:template_id/communication/:device_id/:tab_name',
    component: CommunicationDetails,
    id: 'communication_details',
  },

  {
    url: `/:mission_name/:mission_id/:sat_name/:sat_id/payload_details/:pay_name/:payload_id/:type`,
    component: PayloadDetails,
    id: 'payloadDetails',
  },

  {
    url: '/:mission_name/:mission_id/simulate',
    component: Simulate,
    id: 'simulate',
  },
  {
    url: '/:mission_name/:mission_id/:sat_name/:sat_id/truetwin_details/:truetwin_name/:truetwin_id/:tab_name',
    component: TrueTwinDetails,
    id: 'trueTwinDetails',
  },

  {
    url: "/:mission_name/:mission_id/:sat_name/:sat_id/:truetwin_name/:truetwin_id/:dashboard/:scenario_name/:scenario_id/:tab_name",
    component: CommandCenterDashboard,
    id: 'command_center',
  },

  {
    url: '/:mission_name/:mission_id/operate-dashboard',
    component: Operate,
    id: 'operate-dashboard',
  },
  {
    url: '/:mission_name/:mission_id/:sat_name/:sat_id/:truetwin_name/:truetwin_id/operate/:scenario_name/:scenario_id/dashboard/:type',
    component: Dashboard,
    id: 'operate_dashboard',
  },

  {
    url: '/:mission_name/:mission_id/:sat_name/:sat_id/:truetwin_name/:truetwin_id/scenario/:scenario_id/:validation_name/:validation_id/:validation_status/validation',
    component: ValidationOutPut,
    id: 'validation',
  },
  {
    url: '/catalog',
    component: Catalog,
    id: 'catalog',
  },
  {
    url: '/:mission_name/:mission_id/:sat_name/:sat_id/:truetwin_name/:truetwin_id/create_scenario/:scenario_step',
    component: NewScenarioFlow,
    id: 'create_new_scenario',
  },
  {
    url: '/:mission_name/:mission_id/:sat_name/:sat_id/:truetwin_name/:truetwin_id/update_scenario/:scenario_name/:scenario_id/:scenario_step',
    component: NewScenarioFlow,
    id: 'update_scenario',
  },

  {
    url: '/:mission_name/:mission_id/ground_station/:station_name/:station_id',
    component: GroundStationsDetails,
    id: 'ground_station',
  },

  {
    url: '/:mission_name/:mission_id/station_provider/:provider_name/:provider_id/:tab_name',
    component: GSProviderDetails,
    id: 'station_provider',
  },
  {
    url: '/:mission_name/:mission_id/mission_modeling',
    component: MissionModeling,
    id: 'mission_modeling',
  },
  {
    url: '/:mission_name/:mission_id/draw_feature',
    component: DrawFeature,
    id: 'draw_feature',
  },
  {
    url: '/:mission_name/:mission_id/edit_feature',
    component: DrawFeature,
    id: 'edit_feature',
  },
  {
    url: '/:mission_name/:mission_id/shape_feature',
    component: ShapeFileFeature,
    id: 'shape_feature',
  },
  {
    url: '/:mission_name/:mission_id/new_mission_modeling/:scenario_id',
    component: MissionModelling,
    id: 'new_mission_modeling',
  },
  {
    url: "/:mission_name/:mission_id/collect",
    component: UnifiedControlPlane,
    id: "unifiedControlPlane",
  },

];

export { RouterUrl };
