//@ts-nocheck
import {
    ButtonGroup,
    Dropdown,
} from "react-bootstrap";
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    MissionStore,
    reSetSatDetails,
    setSatelliteClone,
    setSatelliteEdit,
    setSatellites,
} from '../../../store/MissionSlice';
import Dialog from '../../CommonComponents/Dialog';
import { navigateTo } from '../../CommonComponents/CommonFunctions';
import { Box } from '@mui/material';
import { toast } from 'react-toastify';
import MissionService from '../../../service/MissionService';
import TrueTwinConfig from '../TrueTwins/TrueTwinConfig';
import CommandCenterService from '../../../service/CommandCenterService';
import CreateSatModel from '../Satellites/CreateSatModel';
import CustomToolTip from "../../CommonComponents/CustomToolTip";
import Loader from "../../../Layouts/Loader/Loader";
import OperateModal from "../Operate/modal/OperateModal";

const Satellites = () => {
    const params = useParams();
    const { mission_name, mission_id } = params
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [showBtnGroup, setShowBtnGroup] = React.useState('');
    const [expanded, setExpanded] = useState<any>('');

    const [loading, setLoading] = React.useState(true);
    const userData = useSelector((state: { mission: MissionStore }) => state.mission.userData);
    const Satellites = useSelector((state: { mission: MissionStore }) => state.mission.Satellites);
    const satelliteEdit = useSelector((state: { mission: MissionStore }) => state.mission.satelliteEdit);
    const satelliteClone = useSelector((state: { mission: MissionStore }) => state.mission.satelliteClone);

    useEffect(() => { dispatch(reSetSatDetails()) }, [])

    const [satelliteData, setSatelliteData] = React.useState({})
    const [satellite_name, setSatellite_name] = React.useState(mission_name);
    const [satellite_des, setSatellite_des] = React.useState('');
    const [openSatModel, setOpenSatModal] = React.useState(false);
    const [openDeleteModel, setOpenDeleteModal] = React.useState(false);
    const [promoteSatellite, setPromoteSatellite] = React.useState(false);
    const [openCreateTrueTwin, setOpenCreateTrueTwin] = React.useState({
        isCreateTrueTwin: false,
        sat_id: ''
    });
    const [isGetTT, setIsGetTT] = React.useState(false)

    const get_satellites = () => {
        MissionService.get_satellites_by_mission_id(mission_id)?.then((res) => {
            if (res) {
                dispatch(setSatellites(res?.data))
                setIsGetTT(true)
            }
            setLoading(false)
        }).catch((err) => {
            console.error(err);
            setLoading(false)
        })
    }


    useEffect(() => {
        if (mission_id) {
            get_satellites()
        }
    }, [mission_id])


    const deletesatellite = (satelliteData) => {
        setLoading(true)
        MissionService.delete_satellite(satelliteData?.['uniqueId'])?.then((res) => {
            if (res) {
                toast.success('Satellite deleted successfully', { toastId: 'o' })
                get_satellites()
            } else {
                setLoading(false)
            }
        }).catch((err) => {
            console.error(err);
            toast.error("Something went wrong while deleting satellite. Please try again. ", { toastId: 'o' });
            setLoading(false)
        })
    }

    const create_new_satellite = async () => {
        if (satellite_name !== '') {
            let sat = {
                name: satellite_name,
                description: satellite_des,
                draftMode: 'Yes',
                "containing-mission": mission_id,
                versions: [
                    {
                        assembly: {
                            Payloads: [],
                            Edge: [],
                            Template: '',
                        },
                    },
                ],
                "swap-info": {
                    totalBusSize: 0,
                    totalMass: 0,
                    totalPower: 0,
                    totalBusSizePercent: 0,
                    totalMassPercent: 0,
                    totalPowerPercent: 0,
                    totalPayloadSize: 0,
                    totalPayloadMass: 0,
                    totalPayloadPower: 0,
                },
            };
            MissionService.create_new_satellites(sat)
                ?.then((res) => {
                    if (res.data) {
                        navigateTo(`${mission_name}/${mission_id}/${satellite_name}/${res?.data?.['uniqueId']}/add_new_payload`, navigate)
                    }
                })
                .catch((err) => {
                    if (err.response?.data === "Satellite with same name already exists") {
                        toast.error('The name you entered is already in use for another satellite.', { toastId: 'o' });
                    }
                    setOpenSatModal(false)
                });
        } else {
            toast.error('Please enter satellite name', { toastId: 'o' })
        }
    }
    const clone_existing_satellite = () => {
        setOpenSatModal(false)
        setLoading(true)
        MissionService.clone_satellite(satelliteData?.['uniqueId'], satellite_name)?.then((res) => {
            if (res.data) {
                toast.success('Satellite cloned successfully', { toastId: 'o' })
                get_satellites()
                dispatch(setSatelliteClone(false))
                setSatellite_name('')
                setSatellite_des('')
            }
        }).catch((err) => {
            if (err.response?.data === "Satellite with same name already exists") {
                toast.error('The name you entered is already in use for another satellite.', { toastId: 'o' });
            }
            dispatch(setSatelliteClone(false))
            setLoading(false)
        })
    }


    const edit_satellite = () => {
        let sat = {
            ...satelliteData,
            'name': satellite_name,
            'description': satellite_des
        }
        MissionService.update_satellites(satelliteData?.['uniqueId'], sat)
            ?.then((res) => {
                if (res.data) {
                    if (!satelliteData?.['versions']?.[0]?.['assembly']?.['Payloads']) {
                        return navigateTo(`${mission_name}/${mission_id}/${satellite_name}/${satelliteData?.['uniqueId']}/add_new_payload`, navigate)
                    }
                    else if (satelliteData?.['draftMode'] === 'No') {
                        return navigateTo(`${mission_name}/${mission_id}/${satellite_name}/${satelliteData?.['uniqueId']}/review_satellite`, navigate)
                    } else {
                        return navigateTo(`${mission_name}/${mission_id}/${satellite_name}/${satelliteData?.['uniqueId']}/add_new_template`, navigate)
                    }
                }
                setSatellite_name('')
                setSatellite_des('')
                dispatch(setSatelliteEdit(false))
            })
            .catch((err) => {
                if (err.response?.data === "Satellite with same name already exists") {
                    toast.error('The name you entered is already in use for another satellite.', { toastId: 'o' });
                }
                setOpenSatModal(false)
                dispatch(setSatelliteEdit(false))
            });
    }




    const navigateTo_Sat_details = (sat) => {
        navigateTo(`${mission_name}/${mission_id}/sat_details/${sat?.['name']}/${sat?.['uniqueId']}/Payloads`, navigate);
    }

    const navigate_to_mission = () => {
        navigateTo(``, navigate)
    }


    const navigate_to_view_satellite = (sat) => {
        navigateTo(`${mission_name}/${mission_id}/view_satellite/${sat?.['name']}/${sat?.['uniqueId']}/view`, navigate)
    }

    const navigate_to_operate = () => {
        navigateTo(`${mission_name}/${mission_id}/operate-dashboard`, navigate)
    }


    return (
        <div className='h-100 w-100' onClick={() => {
            setShowBtnGroup('')
            setExpanded('')
        }}>
            {loading && <Loader />}
            <div className='p-7'>
                <div className="mission-card-view gap-3 mt-5">
                    {Satellites?.length > 0 && Satellites?.map((satellite, i) => {
                        return (
                            <div key={i}>
                                <div className="mission-card gap-2 d-flex flex-column" key={i} onClick={(e) => {
                                    e.preventDefault();
                                    if (satellite?.['draftMode'] && satellite?.['draftMode'] === 'Yes') {
                                        toast.warning('Satellite configuration is incomplete. Edit to Configure.', { toastId: 'o' })
                                    } else {
                                        navigateTo_Sat_details(satellite)
                                    }
                                }}>
                                    <div className="d-flex justify-content-between">
                                        <div className='d-flex flex-column'>
                                            <div className="d-flex align-items-center gap-2">
                                                <img src={require('../../../assets/images/sat.svg').default} width='20px' height='20px' alt={`Satellite`} />
                                                <span className='mission-name'>{satellite?.['name']}</span>
                                                {(satellite?.['draftMode'] && satellite?.['draftMode'] === 'Yes') && <span className='color-atmos fs-14 font-italic'>(Configuration pending)</span>}
                                            </div>
                                            <span className='text-gray text-truncate'>{satellite?.['summery']}</span>
                                        </div>
                                        <span className="text-center align-middle" onClick={(e) => {
                                            e.stopPropagation()
                                            setShowBtnGroup('')
                                        }}>
                                            <ButtonGroup size="sm" className="flex-nowrap" >
                                                {userData['resource_access']?.['ATMOS-RESOURCE-SERVER']?.['roles'][0] !== 'project_viewer' &&
                                                    <div className="btn-list more-button">
                                                        <Dropdown drop="end" show={showBtnGroup === satellite?.['uniqueId']}>
                                                            <Dropdown.Toggle size="sm" variant="transparent" onClick={(e) => {
                                                                e.stopPropagation()
                                                                if (showBtnGroup === satellite?.['uniqueId']) {
                                                                    setShowBtnGroup('')
                                                                } else {

                                                                    setShowBtnGroup(satellite?.['uniqueId'])
                                                                }
                                                            }}>
                                                                <i className="fe fe-more-vertical fs-20"></i>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                {satellite?.['draftMode'] && satellite?.['draftMode'] === 'No' && <Dropdown.Item eventKey="0"
                                                                    onClick={(e) => {
                                                                        navigate_to_view_satellite(satellite)
                                                                    }}>
                                                                    View
                                                                </Dropdown.Item>}
                                                                <Dropdown.Item eventKey="1"
                                                                    onClick={(e) => {
                                                                        if (satellite?.['trueTwinIds'] && satellite?.['trueTwinIds']?.length > 0) {
                                                                            toast.error('Cannot edit satellite with TrueTwin', { toastId: 'o' })
                                                                        } else {
                                                                            dispatch(setSatelliteEdit(true))
                                                                            setSatellite_name(satellite?.['name']);
                                                                            setSatellite_des(satellite?.['description']);
                                                                            setSatelliteData(satellite);
                                                                            setOpenSatModal(true);
                                                                        }
                                                                    }}>
                                                                    Edit
                                                                </Dropdown.Item>
                                                                <Dropdown.Item eventKey="2"
                                                                    onClick={() => {
                                                                        let sat_name = satellite?.['name']?.includes('copy') ? satellite?.['name']?.includes('copy-') ? satellite?.['name']?.split('(')[0] + '(copy-' + `${+satellite?.['name']?.split('-')[1][0] + 1}` + ')' : satellite?.['name']?.split('(')[0] + '(copy-1)' : satellite?.['name'] + ' (copy)'
                                                                        dispatch(setSatelliteClone(true))
                                                                        setSatellite_name(sat_name);
                                                                        setSatellite_des(satellite?.['description'])
                                                                        setSatelliteData(satellite);
                                                                        setOpenSatModal(true)
                                                                    }}>
                                                                    Clone
                                                                </Dropdown.Item>
                                                                {userData?.['feature-allowed']?.['operate'] === 'Yes' &&
                                                                    <Dropdown.Item eventKey="3"
                                                                        onClick={() => {
                                                                            setPromoteSatellite(satellite);
                                                                        }}>
                                                                        Promote
                                                                    </Dropdown.Item>
                                                                }
                                                                <Dropdown.Item eventKey="4"
                                                                    onClick={(e) => {
                                                                        setSatelliteData(satellite);
                                                                        setOpenDeleteModal(true);
                                                                    }}>
                                                                    Delete
                                                                </Dropdown.Item>
                                                                <Dropdown.Item eventKey="5"
                                                                    onClick={(e) => {
                                                                        if (satellite?.['draftMode'] && satellite?.['draftMode'] === 'Yes') {
                                                                            toast.warning('Satellite configuration is incomplete. Configure it before create TrueTwin.', { toastId: 'o' })
                                                                        } else {
                                                                            if (userData?.['feature-allowed']?.['ttMaxLimit'] === (satellite?.['trueTwinIds'] && satellite?.['trueTwinIds']?.length)) {
                                                                                toast.warning('You have reached the maximum number of TrueTwins', { toastId: 'o' })
                                                                            } else {
                                                                                setOpenCreateTrueTwin({
                                                                                    isCreateTrueTwin: true,
                                                                                    sat_id: satellite?.['uniqueId']
                                                                                })

                                                                            }
                                                                        }
                                                                    }}>
                                                                    Create TrueTwin
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                }
                                            </ButtonGroup>
                                        </span>
                                    </div>

                                    <div className='d-flex gap-2 align-items-center me-3 mt-5'>
                                        <span style={{ opacity: 0.8, fontSize: '16px', height: '36px', alignItems: 'center', display: 'flex', justifyContent: 'center' }} >Payload</span>
                                        {satellite?.['versions']?.[0]?.['assembly']?.['Payloads']?.length > 0 &&
                                            satellite?.['versions']?.[0]?.['assembly']?.['Payloads']?.map((item, id) => {
                                                let payloadType = item?.['atmos-data']?.['component_specific_attributes']?.['payloadType']
                                                if (id < 4) {
                                                    return (
                                                        <CustomToolTip
                                                            title={item?.['user_data']?.['payload_name']}
                                                            key={id}
                                                            placement="top"
                                                        >
                                                            <Box className='payload-hover-ani' onClick={(e) => {
                                                                e.stopPropagation()
                                                                if (satellite?.['draftMode'] && satellite?.['draftMode'] === 'Yes') {
                                                                    toast.warning('Satellite configuration is incomplete. Edit to Configure .', { toastId: 'o' })
                                                                } else {
                                                                    navigateTo(`${mission_name}/${mission_id}/${satellite['name']}/${satellite['uniqueId']}/payload_details/${item?.['user_data']?.['payload_name']}/${item?.['user_data']?.['id']}/${'Payloads'}`, navigate)
                                                                }
                                                            }}>
                                                                {payloadType === 'Earth Observation' && <img src={require('../../../assets/images/EarthObserve.svg').default} width='28px' height='28px' alt={payloadType} />}
                                                                {payloadType === 'Communication' && <img src={require('../../../assets/images/Communication.svg').default} width='28px' height='28px' alt={payloadType} />}
                                                                {payloadType === 'Other' && <img src={require('../../../assets/images/Other.svg').default} width='28px' height='28px' alt={payloadType} />}
                                                            </Box>
                                                        </CustomToolTip>
                                                    )
                                                }
                                            })}
                                        {satellite?.['versions']?.[0]?.['assembly']?.['Payloads']?.length > 4 && <Box className='payload-hover-ani'>
                                            <i className="fe fe-plus fs-12"></i>{satellite?.['versions']?.[0]?.['assembly']?.['Payloads']?.length - 4}
                                        </Box>}
                                    </div>
                                    <div className={`mission-card-footer w-100 pt-4 mt-3`}>
                                        <TrueTwinIcon setOpenCreateTrueTwin={setOpenCreateTrueTwin} satellite={satellite} getTT={isGetTT} setIsGetTT={setIsGetTT} />
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    {userData['resource_access']?.['ATMOS-RESOURCE-SERVER']?.['roles'][0] !== 'project_viewer' &&
                        <div className="add-mission-card" onClick={() => {
                            setSatellite_name('')
                            setOpenSatModal(true)
                        }}>
                            <div className="d-flex flex-column mt-2 align-items-center gap-2">
                                <i className="fe fe-plus fs-40 mb-1 align-self-center"></i>
                                <strong>Create Satellite</strong>
                            </div>
                        </div>
                    }
                </div>
            </div>

            {openSatModel &&
                <CreateSatModel
                    close={() => {
                        if (satelliteEdit) {
                            dispatch(setSatelliteEdit(false))
                        } if (satelliteClone) {
                            dispatch(setSatelliteClone(false))
                        }
                        setOpenSatModal(false)
                    }}
                    satellite_name={satellite_name} setSatellite_name={setSatellite_name} satellite_des={satellite_des}
                    setSatellite_des={setSatellite_des} onclick={satelliteEdit ? edit_satellite : satelliteClone ? clone_existing_satellite : create_new_satellite}
                    type={satelliteEdit ? 'Update' : satelliteClone ? 'Clone' : 'Create'} />
            }

            <Dialog
                onClick={(event) => {
                    if (event) {
                        deletesatellite(satelliteData);
                    }
                    setOpenDeleteModal(false)
                }}
                openModel={openDeleteModel} comment={`${satelliteData?.['trueTwinIds']?.length > 0 ? 'The satellite has TrueTwins associated with it. Are you sure you want to delete this satellite along with all its TrueTwins and their data?' : 'Are you sure you want to delete this satellite?'}`}
            />

            {openCreateTrueTwin.isCreateTrueTwin && <TrueTwinConfig mission_name={mission_name} mission_id={mission_id} sat_id={openCreateTrueTwin.sat_id} close={() => {
                setOpenCreateTrueTwin({ isCreateTrueTwin: false, mission_name: '', mission_id: '', sat_id: '' })
                setIsGetTT(true)
            }} />}
            {promoteSatellite && <OperateModal close={() => {
                setPromoteSatellite(false)
                navigate_to_operate()
            }} hasSelectedSatellite={promoteSatellite} openModal={{ type: 'promote' }} />}
        </div>
    )
}



const TrueTwinIcon = ({ setOpenCreateTrueTwin, satellite, getTT, setIsGetTT }) => {
    const params = useParams();
    const navigate = useNavigate();
    const { mission_name, mission_id } = params
    const [TrueTwinData, setTrueTwinData] = useState([])

    useEffect(() => {
        if (getTT && satellite['uniqueId']) {
            CommandCenterService.get_TrueTwins_by_satId(satellite['uniqueId'])?.then((tt) => {
                if (tt?.data) {
                    setTrueTwinData(tt?.data)
                }
            }).catch((e) => {
                console.log(e);
            })
            setIsGetTT(false)
        }
    }, [getTT, satellite['uniqueId']])

    return (

        <div className='d-flex gap-2 align-items-center'>
            {TrueTwinData?.length === 0 && <span className='fs-13 d-flex gap-2 align-items-center' onClick={(e) => {
                e.stopPropagation()
                if (satellite?.['draftMode'] && satellite?.['draftMode'] === 'Yes') {
                    toast.warning('Satellite configuration is incomplete. Configure it before create TrueTwin.', { toastId: 'o' })
                } else {
                    setOpenCreateTrueTwin({
                        isCreateTrueTwin: true,
                        sat_id: satellite?.['uniqueId']
                    })
                }
            }}>
                <span className='fs-14 d-flex color-atmos'>Create TrueTwin</span>
                <CustomToolTip
                    title={'Create TrueTwin'}
                    placement="top"
                >
                    <span className='add-mission-TrueTwin'>
                        <i className="fe fe-plus fs-16 color-atmos"></i>
                    </span>
                </CustomToolTip>
            </span>}
            {TrueTwinData?.length > 0 && <span className='fs-14 color-atmos'>TrueTwin</span>}

            {TrueTwinData?.length > 0 && TrueTwinData?.map((truetwin, i) => {
                if (i < 3) {
                    return (
                        <CustomToolTip
                            key={i}
                            title={truetwin?.['Name']}
                            placement="top"
                        >
                            <Box className='truetwin-hover-ani' onClick={(e) => {
                                e.stopPropagation()
                                navigateTo(`${mission_name}/${mission_id}/${satellite['name']}/${satellite['uniqueId']}/truetwin_details/${truetwin?.['Name']}/${truetwin?.['trueTwinId']}/Configuration`, navigate)
                            }}>
                                <img src={require('../../../assets/images/TrueTwin_Icon.svg').default} width='26px' height='26px' alt={`TrueTwin`} />
                            </Box>
                        </CustomToolTip>
                    )
                }
            })}
            {TrueTwinData?.length > 3 && <span className='add-mission-TrueTwin'>
                <span className='d-flex align-items-center' style={{ fontSize: '12px', marginRight: '3px' }}>
                    <i className="fe fe-plus fs-12"></i>{TrueTwinData?.length - 3}
                </span>
            </span>}
        </div>
    )

}

export default Satellites
