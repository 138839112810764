import { Fragment, useState } from 'react'
import { GenerateSvg } from '../components/center/planning/Svgs'
import GenerateSchedule from '../components/center/planning/schedule/generate_schedule/GenerateSchedule'
import CustomToolTip from '../../CommonComponents/CustomToolTip'

const GenerateScheduleBurtton = () => {

    const [show_modal, set_show_modal] = useState(false)

    return (
        <Fragment>
            <CustomToolTip title='Generate Schedule' placement='bottom'>
                <button
                    onClick={(e) => {
                        e.stopPropagation()
                        set_show_modal(!show_modal)
                    }}
                    className={`${show_modal && 'active'}`}
                >
                    <GenerateSvg />
                </button>
            </CustomToolTip>
            <GenerateSchedule
                open={show_modal}
                close={() => set_show_modal(false)}
            />
        </Fragment>
    )
}

export default GenerateScheduleBurtton