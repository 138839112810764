import { useEffect, useRef, useState } from "react";
import useOrbit from "../../../../hooks/useOrbit";
import useUrlParams from "../../../../hooks/useUrlParams";
import * as Cesium from "cesium";
import { getGroundStationPacket, getSatellitePackete, groundStationToSatelliteConnection } from "../../../../hooks/cesiumUtils";
import { ContactStation } from "../../../../hooks/useGroundContact";
import CustomLoader from "../../../../custom_components/CustomLoader";
import useViewer from "../../../../hooks/useViewer";
import { get_formated_time } from "../../../../utils";

interface MapProps {
    selected_contact: {
        contact_type: string;
        contact_data: ContactStation,
    } | null
}

const Map = ({ selected_contact }: MapProps) => {
    const { setViewer, current_simulation_time } = useViewer()
    const { orbitData } = useOrbit()
    const { scenario_id, dashboard } = useUrlParams()
    const [loading, setLoading] = useState<boolean>(true)
    const cesiumContainerRef = useRef<HTMLDivElement | null>(null)
    const [map_viewer, set_map_viewer] = useState<Cesium.Viewer | null>(null)

    let Imaginary = new Cesium.MapboxStyleImageryProvider({
        styleId: 'dark-v10',
        accessToken: process.env.REACT_APP_MAPBOX_TOKEN!
    });

    const update_base_map_layer = (map_viewer, baseMapOption) => {
        switch (baseMapOption) {
            case "Mapbox Satellite": {
                var imaginary = new Cesium.MapboxStyleImageryProvider({
                    styleId: 'satellite-v9',
                    accessToken: process.env.REACT_APP_MAPBOX_TOKEN!
                });
                map_viewer.imageryLayers.addImageryProvider(imaginary);
                break;
            }
            case "Mapbox Dark": {
                var imaginary = new Cesium.MapboxStyleImageryProvider({
                    styleId: 'dark-v10',
                    accessToken: process.env.REACT_APP_MAPBOX_TOKEN!
                });
                map_viewer.imageryLayers.addImageryProvider(imaginary);
                break;
            }
            case "Mapbox Light": {
                var imaginary = new Cesium.MapboxStyleImageryProvider({
                    styleId: 'light-v10',
                    accessToken: process.env.REACT_APP_MAPBOX_TOKEN!
                });
                map_viewer.imageryLayers.addImageryProvider(imaginary);
                break;
            }
        }

    }

    useEffect(() => {
        if (cesiumContainerRef.current && scenario_id && !map_viewer) {
            let cesium_viewer = new Cesium.Viewer(cesiumContainerRef.current, {
                requestRenderMode: true,
                shouldAnimate: true,
                animation: false, // Disable the default animation widget
                baseLayerPicker: false, // Disable the default base layer picker
                timeline: false, // Disable the timeline
                geocoder: false, // Disable the geocoder
                homeButton: false, // Disable the home button
                navigationHelpButton: false, // Disable the navigation help button
                sceneModePicker: false, // Disable the scene mode picker
                selectionIndicator: false, // Disable the selection indicator
                fullscreenButton: false,
                imageryProvider: Imaginary
            });
            const map_details = JSON.parse(sessionStorage.getItem('map_details') || '{}');

            if (map_details[scenario_id]) {
                if (map_details?.[scenario_id]?.["Eclipse"] && !cesium_viewer.scene.globe.enableLighting) {
                    cesium_viewer.scene.globe.enableLighting = cesium_viewer.scene.globe.enableLighting
                } else if (!map_details?.[scenario_id]?.["Eclipse"] && cesium_viewer.scene.globe.enableLighting) {
                    cesium_viewer.scene.globe.enableLighting = !cesium_viewer.scene.globe.enableLighting
                }
                cesium_viewer.scene.morphTo2D(2);
                update_base_map_layer(cesium_viewer, map_details?.[scenario_id]?.["Base Map"]);
            } else {
                cesium_viewer.scene.morphTo2D(2)
                var imaginary = new Cesium.MapboxStyleImageryProvider({
                    styleId: 'dark- v10',
                    accessToken: process.env.REACT_APP_MAPBOX_TOKEN!
                });
                cesium_viewer.imageryLayers.addImageryProvider(imaginary);
                let details = {
                    ...map_details,
                    [scenario_id]: {
                        "View": '2D',
                        "Base Map": 'Mapbox Dark',
                        "Eclipse": false,
                    }
                }
                sessionStorage.setItem('map_details', JSON.stringify(details));
            }
            cesium_viewer.camera.setView({
                destination: Cesium.Cartesian3.fromDegrees(0, 0, 77000000)
            });
            cesium_viewer.scene.globe.baseColor = Cesium.Color.TRANSPARENT
            cesium_viewer.scene.globe.showGroundAtmosphere = true;
            cesium_viewer.clock.currentTime = cesium_viewer.clock.startTime;
            cesium_viewer.clock.shouldAnimate = true;
            cesium_viewer.clock.clockRange = Cesium.ClockRange.LOOP_STOP;
            cesium_viewer.scene.screenSpaceCameraController.enableRotate = true;
            cesium_viewer.scene.screenSpaceCameraController.enableTranslate = true;
            cesium_viewer.scene.screenSpaceCameraController.enableZoom = true;
            cesium_viewer.scene.screenSpaceCameraController.enableTilt = true;
            cesium_viewer.scene.screenSpaceCameraController.enableLook = true;
            cesium_viewer.scene.highDynamicRange = false;

            set_map_viewer(cesium_viewer)
        }
    }, [scenario_id])

    useEffect(() => {
        if (orbitData && map_viewer) {
            let document = orbitData.documentMetadata
            let satellites = orbitData?.['satellite'] ? orbitData?.['satellite'] : []
            let groundStations = orbitData?.['groundStation'] ? orbitData?.['groundStation'] : []
            let satToGsConnection = orbitData?.['satToGsConnection'] ? orbitData?.['satToGsConnection'] : []
            createPackets(document, satellites, groundStations, satToGsConnection)
        }
    }, [orbitData, map_viewer])

    const createPackets = (document, satellites, groundStations, satToGsConnection) => {
        if (!map_viewer || !map_viewer.dataSources) return
        // clean the previous czml or data source
        map_viewer.dataSources.removeAll();
        let czmlPackages: any[] = []
        let satelliteczml: any[] = [];
        let groundStationczml: any[] = [];
        let satToGsConnections: any[] = [];

        const filter_gs = groundStations.filter((gs) => {
            return gs.gsId === selected_contact?.contact_data?.id
        })

        satelliteczml = getSatellitePackete(document, satellites)  // Create the satellite packet
        groundStationczml = getGroundStationPacket(satellites, filter_gs, true)  // Create the Ground statio packet
        satToGsConnections = groundStationToSatelliteConnection(satToGsConnection)

        if (satelliteczml?.length > 0) {
            czmlPackages.push(...satelliteczml)
        } if (groundStationczml?.length > 0) {
            czmlPackages.push(...groundStationczml)
        } if (satToGsConnections?.length > 0) {
            czmlPackages.push(...satToGsConnections)
        }
        if (czmlPackages.length > 0) {
            if (dashboard === 'summary' && current_simulation_time > 0) {
                if (czmlPackages[0]?.clock) {
                    czmlPackages[0].clock.currentTime = get_formated_time(current_simulation_time / 1000)
                }
            }
            map_viewer.dataSources.add(Cesium.CzmlDataSource.load(czmlPackages));
            map_viewer.scene.requestRender();
        }
        setViewer(map_viewer)
    }

    useEffect(() => {
        if (selected_contact?.contact_data && map_viewer) {
            let { latitude, longitude } = selected_contact?.contact_data;
            map_viewer.camera.flyTo({
                destination: Cesium.Cartesian3.fromDegrees(parseFloat(longitude), parseFloat(latitude), 5000000), // Adjusted altitude for less zoom
                orientation: {
                    heading: Cesium.Math.toRadians(0.0), // Optional: heading in radians
                    pitch: Cesium.Math.toRadians(-30.0), // Adjusted: pitch in radians to avoid extreme pitch causing errors
                    roll: 0.0                            // Optional: roll in radians
                },
                duration: 2.0 // Optional: duration of the flight in seconds
            });
            setTimeout(() => {
                setLoading(false)
            }, 4000)
        }
    }, [map_viewer, selected_contact])

    return (
        <div style={{ height: '100%', width: '100%', position: 'relative' }}>
            <div
                style={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    objectFit: 'contain',
                }}
                ref={cesiumContainerRef}
            />
            {loading && <div
                style={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: '#000',
                    position: 'absolute',
                    zIndex: 90,
                    flex: 1,
                    top: 0,
                    left: 0,
                }}
            >
                <CustomLoader loading={loading} />
            </div>
            }
        </div >
    )
}

export default Map