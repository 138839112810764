import useViewer from '../hooks/useViewer';
import './PreviewSlider.css'
import { useState } from 'react';
import { NextSvg, PlayPouseSvg, PreviousSvg } from './Svgs';

const PreviewSlider = () => {

    const { viewer } = useViewer()
    const [value, setValue] = useState(1);
    const [play, setPlay] = useState(true);

    const click_next = () => {
        if (value === 32 || !viewer) return
        switch (value) {
            case 1:
                setValue(2)
                viewer.clock.multiplier = 2;
                break;
            case 2:
                setValue(8)
                viewer.clock.multiplier = 8;
                break;
            case 8:
                setValue(32)
                viewer.clock.multiplier = 32;
                break;
            case -32:
                setValue(-8)
                viewer.clock.multiplier = -8;
                break;
            case -8:
                setValue(-2)
                viewer.clock.multiplier = -2;
                break;
            case -2:
                setValue(1)
                viewer.clock.multiplier = 1;
                break;
            default:
                setValue(1)
                viewer.clock.multiplier = 1;
                break;
        }
    }

    const click_previous = () => {
        if (value === -32 || !viewer) return
        switch (value) {
            case 1:
                setValue(-2)
                viewer.clock.multiplier = -2;
                break;
            case -2:
                setValue(-8)
                viewer.clock.multiplier = -8;
                break;
            case -8:
                setValue(-32)
                viewer.clock.multiplier = -32;
                break;
            case 32:
                setValue(8)
                viewer.clock.multiplier = 8;
                break;
            case 8:
                setValue(2)
                viewer.clock.multiplier = 2;
                break;
            case 2:
                setValue(1)
                viewer.clock.multiplier = 1;
                break;
            default:
                setValue(1)
                viewer.clock.multiplier = 1;
                break;
        }
    }

    const click_play_pouse = () => {
        if (!viewer) return
        if (play) {
            setPlay(false)
            viewer.clock.multiplier = 0;
        } else {
            setPlay(true)
            viewer.clock.multiplier = value;
        }
    }

    return (
        <div className="preview__slider__container">
            <span className={`left__count__container ${value < 0 && 'show__container'}`}>{value}x</span>
            <div className='buttons__container'>
                <button className='previous__button' onClick={click_previous}>
                    <PreviousSvg active={value <= -2 ? true : false} />
                    <PreviousSvg active={value <= -8 ? true : false} />
                    <PreviousSvg active={value <= -32 ? true : false} />
                </button>
                <button className='play_pouse__buton' onClick={click_play_pouse}>
                    <PlayPouseSvg state={play ? 'play' : 'pause'} />
                </button>
                <button className='next__button' onClick={click_next}>
                    <NextSvg active={value >= 2 ? true : false} />
                    <NextSvg active={value >= 8 ? true : false} />
                    <NextSvg active={value >= 32 ? true : false} />
                </button>
            </div>
            <span className={`right__count__container ${value > 1 && 'show__container'}`}>{value}x</span>
        </div>
    );
};

export default PreviewSlider;
