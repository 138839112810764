//@ts-nocheck
import { FC, Fragment, useEffect, useState } from 'react'
import * as Cesium from "cesium";
import GroundStationCard from '../../../../../CommonComponents/GroundStationCard';

interface GsVisualizationProps {
    ground_stations: any[];
    selected_stations: any[]
    selectedEntity: any;
    selectFromList: boolean;
    setSelectedEntity: (data) => void
}

const GsVisualization: FC<GsVisualizationProps> = ({ ground_stations, selected_stations, selectedEntity, selectFromList, setSelectedEntity }) => {

    const [viewer, setViewer] = useState<Cesium.Viewer | null>(null);
    const [hoveredGs, setHoveredGs] = useState<any>(null)

    const resetPosition = (viewer: Cesium.Viewer | null, position: number) => {
        if (viewer) {
            viewer.camera.flyTo({
                destination: Cesium.Cartesian3.fromDegrees(0, 0, position)
            });
        }
    }

    let imaginary = new Cesium.MapboxStyleImageryProvider({
        styleId: 'satellite-v9',
        accessToken: process.env.REACT_APP_MAPBOX_TOKEN
    });

    const getGroundStationPackets = () => {
        let gsPacketList: any[] = [];
        gsPacketList.push({
            "clock": {
                "multiplier": 1,
                "range": "CLAMPED",
                "step": "SYSTEM_CLOCK_MULTIPLIER"
            },
            "id": "document",
            "name": "Ground Stations",
            "version": "1.0"
        })
        ground_stations?.map((station) => {
            gsPacketList.push(
                {
                    "billboard": {
                        "horizontalOrigin": "CENTER",
                        "image": selected_stations?.includes(station?.groundStationId) ? '/assets/select_gs.svg' : '/assets/un_select_gs.svg',
                        "pixelOffset": {
                            "cartesian2": [
                                0,
                                0
                            ]
                        },
                        "show": true,
                        "verticalOrigin": "CENTER"
                    },
                    "label": {
                        "fillColor": {
                            "rgba": [
                                255,
                                255,
                                255,
                                255
                            ]
                        },
                        "font": "montserrat",
                        "horizontalOrigin": "LEFT",
                        "outlineColor": {
                            "rgba": [
                                0,
                                0,
                                0,
                                255
                            ]
                        },
                        "outlineWidth": 2,
                        "pixelOffset": {
                            "cartesian2": [
                                15,
                                0
                            ]
                        },
                        "show": true,
                        "style": "FILL_AND_OUTLINE",
                        "verticalOrigin": "CENTER",
                        "text": selected_stations?.includes(station?.groundStationId) ? station?.groundStationName : '',
                    },
                    "name": station?.groundStationName,
                    "position": {
                        "cartographicDegrees": [
                            station?.properties?.longitude,
                            station?.properties.latitude,
                            station?.properties?.altitude ? Number(station?.properties?.altitude) : 0
                        ]
                    },
                    "id": station?.groundStationId
                }
            )
        })
        return gsPacketList
    }

    useEffect(() => {
        if (viewer === null) {
            let cesium_viewer = new Cesium.Viewer("scenariocesiumContainer", {
                requestRenderMode: true,
                shouldAnimate: true,
                animation: false, // Disable the default animation widget
                baseLayerPicker: false, // Disable the default base layer picker
                timeline: false, // Disable the timeline
                geocoder: false, // Disable the geocoder
                homeButton: false, // Disable the home button
                navigationHelpButton: false, // Disable the navigation help button
                sceneModePicker: false, // Disable the scene mode picker
                selectionIndicator: false, // Disable the selection indicator
                fullscreenButton: false,
                imageryProvider: imaginary,
            });

            if (imaginary) {
                cesium_viewer.scene.globe.baseColor = Cesium.Color.TRANSPARENT
            }

            cesium_viewer.scene.morphTo2D(0);
            cesium_viewer.scene.globe.showGroundAtmosphere = true;
            cesium_viewer.clock.currentTime = cesium_viewer.clock.startTime;
            cesium_viewer.clock.shouldAnimate = true;
            cesium_viewer.clock.clockRange = Cesium.ClockRange.LOOP_STOP;
            cesium_viewer.scene.screenSpaceCameraController.enableRotate = true;
            cesium_viewer.scene.screenSpaceCameraController.enableZoom = true;
            cesium_viewer.scene.screenSpaceCameraController.enableTilt = true;
            cesium_viewer.scene.screenSpaceCameraController.enableLook = true;
            cesium_viewer.scene.highDynamicRange = false;

            resetPosition(cesium_viewer, 39000000);
            setViewer(cesium_viewer);
        }

        return () => {
            if (viewer) {
                try {
                    viewer.dataSources.removeAll();
                } catch (error) {
                    console.log(error);
                }
            }
        };
    }, [viewer]);

    useEffect(() => {
        if (viewer && ground_stations?.length > 0) {
            viewer.dataSources.removeAll()
            const dataSource = new Cesium.CzmlDataSource();
            viewer.dataSources.add(dataSource.load(
                [...getGroundStationPackets()]
            ));

            const handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);

            handler.setInputAction((movement) => {
                const pickedObject = viewer.scene.pick(movement.endPosition);
                if (Cesium.defined(pickedObject) && Cesium.defined(pickedObject.id)) {
                    document.body.style.cursor = 'pointer';
                    let entity = pickedObject.id;
                    viewer.selectedEntity = entity;
                    setHoveredGs(entity && entity?._id)
                } else {
                    setHoveredGs(null)
                    document.body.style.cursor = 'auto';
                }

            }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
            return () => {
                handler.destroy();
            };
        }
    }, [viewer, ground_stations, selected_stations])

    useEffect(() => {
        if (viewer && selectedEntity && Cesium.defined(selectedEntity) && selectFromList) {
            const dataSource = viewer.dataSources.get(0); // Assuming there is at least one data source
            const entity = dataSource?.entities.getById(selectedEntity);
            if (entity) {
                viewer.flyTo(entity, {
                    duration: 1.5,
                    offset: new Cesium.HeadingPitchRange(
                        Cesium.Math.toRadians(0),
                        Cesium.Math.toRadians(-45),
                        3500000
                    )
                });
            }
        } else {
            resetPosition(viewer, 39000000);
            // setSelectedEntity(null);
        }
    }, [viewer, selectedEntity, selectFromList]);


    return (
        <Fragment>
            {hoveredGs && viewer && ground_stations?.map(station => {
                if (station?.['groundStationId'] === hoveredGs) {
                    return (
                        <GroundStationCard station={station} viewer={viewer} />
                    )
                }
            })}
            <div id="scenariocesiumContainer" className="d-flex h-100 w-100" onClick={() => {
                setSelectedEntity(null)
                setHoveredGs(null)
            }} />
        </Fragment>
    );
}

export default GsVisualization
