import { Fragment, useEffect, useState } from "react";
import * as Cesium from "cesium";
import { useParams } from "react-router-dom";
import "./scenarioOrbit.css"
import useScenarioViewer from "../hooks/useScenarioViewer";
import ViewerSettings from "../../../../../CommonComponents/ViewerSettings";
import { scenario_visualization } from "./DataSources";
import { truncateNumber } from "../../../../../CommonComponents/CommonFunctions";
import GroundStationCard from "../../../../../CommonComponents/GroundStationCard";
import GroundTargetCard from "../../../../../CommonComponents/GroundTargetCard";

const ScenarioOrbitNew = ({
  all_ground_stations,
  scenarioData,
}) => {
  const params = useParams();
  const {
    scenario_id,
  } = params;

  const { viewer, setViewer } = useScenarioViewer(null)
  const [groundTargets, setGroundTargets] = useState(null)

  const [onSideGroundStation, setOnSideGroundStation] = useState(null)

  // reset rhe position of viewer
  const resetPosition = (viewer, position) => {
    viewer.camera.flyTo({
      destination: Cesium.Cartesian3.fromDegrees(0, 0, position)
    });
  }

  let imaginary = new Cesium.MapboxStyleImageryProvider({
    styleId: 'dark-v10',
    accessToken: 'pk.eyJ1IjoiYW50YXJpcy1tYXBib3giLCJhIjoiY2x2cGNubzF4MDBveTJtb2RtNG5zMGQ2NCJ9.MkPyl-z2FXuFSyYNwP_oaw'
  });

  // this useEffect call only one time once viewer is created then it will not call. purpose ==> prevent the re-rendring
  useEffect(() => {
    if (viewer === null) {
      let cesium_viewer = (new Cesium.Viewer("scenariocesiumContainer", {
        requestRenderMode: true,
        shouldAnimate: true,
        animation: false, // Disable the default animation widget
        baseLayerPicker: false, // Disable the default base layer picker
        timeline: false, // Disable the timeline
        geocoder: false, // Disable the geocoder
        homeButton: false, // Disable the home button
        navigationHelpButton: false, // Disable the navigation help button
        sceneModePicker: false, // Disable the scene mode picker
        selectionIndicator: false, // Disable the selection indicator
        fullscreenButton: false,
        imageryProvider: imaginary,
      }));

      cesium_viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);

      if (imaginary) {
        cesium_viewer.scene.globe.baseColor = Cesium.Color.TRANSPARENT
      }

      const map_details = JSON.parse(sessionStorage.getItem('map_details'))
      if (map_details?.[scenario_id]?.['View'] === '3D') {
        cesium_viewer.scene.morphTo3D(0)
      } else {
        cesium_viewer.scene.morphTo2D(0)
      }
      cesium_viewer.scene.globe.showGroundAtmosphere = false;
      cesium_viewer.clock.currentTime = cesium_viewer.clock.startTime;
      cesium_viewer.clock.shouldAnimate = true;
      cesium_viewer.clock.clockRange = Cesium.ClockRange.LOOP_STOP;
      cesium_viewer.scene.screenSpaceCameraController.enableRotate = true;
      cesium_viewer.scene.screenSpaceCameraController.enableZoom = true;
      cesium_viewer.scene.screenSpaceCameraController.enableTilt = true;
      cesium_viewer.scene.screenSpaceCameraController.enableLook = true;
      cesium_viewer.scene.highDynamicRange = false

      resetPosition(cesium_viewer, 30000000)
      setViewer(cesium_viewer)
    }
  }, [viewer]);


  // when the viewer is ready to use then this useEffect will call handler
  useEffect(() => {
    if (viewer) {
      const handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
      handler.setInputAction((movement) => {
        const pickedObject = viewer.scene.pick(movement.endPosition);
        if (Cesium.defined(pickedObject) && Cesium.defined(pickedObject.id)) {
          let entity = pickedObject.id;
          viewer.selectedEntity = entity;
          if (entity && entity?._id) {
            setOnSideGroundStation(entity && entity?._id)
            if (entity.id.includes('ground_target_') && entity.position) {
              let currentTime = Cesium.JulianDate.now();
              let position = entity.position.getValue(currentTime);
              if (position) {
                let cartographicPosition = Cesium.Cartographic.fromCartesian(position);
                let longitude = Cesium.Math.toDegrees(cartographicPosition.longitude);
                let latitude = Cesium.Math.toDegrees(cartographicPosition.latitude);
                setGroundTargets([{
                  id: entity.id,
                  name: entity.name,
                  longitude: longitude,
                  latitude: latitude,
                }])
              }
            }
          }
        } else {
          setOnSideGroundStation(null)
          setGroundTargets(null)
        }

      }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
      return () => {
        handler.destroy();
      };
    }
  }, [viewer])




  useEffect(() => {
    document.body.style.userSelect = 'none';
    return (() => { document.body.style.userSelect = 'auto'; })
  }, [])

  return (
    <Fragment>
      {onSideGroundStation && viewer && all_ground_stations?.map(station => {

        if (station?.['groundStationId'] === onSideGroundStation) {
          return (
            <GroundStationCard station={station} viewer={viewer} />
          )
        }
      })}

      {viewer && groundTargets && groundTargets?.map(target => {
        // let targetName = target?.['name'];
        // let targetLat = target?.latitude;
        // let targetLong = target?.longitude;

        // var cartographic = Cesium.Cartographic.fromDegrees(targetLong, targetLat);
        // var cartesianPosition = Cesium.Cartographic.toCartesian(cartographic);
        // const popupPosition = Cesium.SceneTransforms.wgs84ToWindowCoordinates(
        //   viewer.scene,
        //   cartesianPosition
        // );

        // let outside_window_x = (popupPosition.x + 360) > window.innerWidth;
        // const top_position = 50
        // let outside_window_y = popupPosition.y - top_position < 0;
        // let top = outside_window_y ? `${((popupPosition.y - top_position) - (popupPosition.y - top_position) + 0)}px` : outside_window_x ? `${popupPosition.y - top_position + 70}px` : `${popupPosition.y - top_position + 50}px`;
        // let left = outside_window_x ?
        //   outside_window_y ? `${popupPosition.x - 360}px` : `${(popupPosition.x + window.innerWidth) - (popupPosition.x + 370)}px` :
        // outside_window_y ? `${popupPosition.x + 20}px` : `${popupPosition.x + 10}px`;
        return (
          // <div className="popup-modal"
          //   key={target?.['groundTargetId']}
          //   style={{
          //     display: 'flex',
          //     flexDirection: "row",
          //     alignContent: 'center',
          //     justifyContent: 'space-between',
          //     cursor: 'pointer',
          //     left: left,
          //     top: top,
          //     transition: 'all 0.3s ease',
          //     padding: '12px',
          //     gap: '16px',
          //     width: 'auto',
          //     height: '33px'
          //   }}>
          //   <div style={{ display: 'flex', flexDirection: 'column', }}>
          //     <span title={targetName}
          //       style={{
          //         color: 'rgba(204, 245, 78, 1.00)', fontSize: '14px', letterSpacing: '0.56px',
          //         maxWidth: '150px',
          //         whiteSpace: 'nowrap',
          //         overflow: 'hidden',
          //         textOverflow: 'ellipsis'
          //       }}>
          //       {targetName}
          //     </span>
          //   </div>

          //   <div style={{ display: 'flex', alignItems: 'center', maxWidth: '200px' }}>
          //     <div className="labelClass">
          //       <span style={{ color: '#EEE', fontSize: '10px', opacity: '0.6' }}>Lat:</span>
          //       <span style={{ color: '#F0F0F0', fontSize: '12px', letterSpacing: '0.56px', marginLeft: '4px' }}>
          //         {truncateNumber(targetLat, 2)}
          //       </span>
          //     </div>
          //     <div style={{ padding: '0px 8px', alignItems: 'center' }}>
          //       <img style={{ width: '1px' }} src={require("../../../../../../assets/images/svgs/line.svg").default} />
          //     </div>
          //     <div className="labelClass">
          //       <span style={{ color: '#EEE', fontSize: '10px', opacity: '0.6' }}>
          //         Lon:
          //       </span>
          //       <span className="labelClass"
          //         style={{ color: '#F0F0F0', fontSize: '12px', letterSpacing: '0.56px', marginLeft: '4px' }}>
          //         {truncateNumber(targetLong, 2)}
          //       </span>
          //     </div>

          //   </div>
          // </div >
          <GroundTargetCard target={target} viewer={viewer} />
        )


      })}

      <div id="scenariocesiumContainer" className="d-flex h-100 w-100" onClick={() => { scenario_visualization().removed_focus_gs(viewer, scenarioData?.['ground_stations']) }} />

      <ViewerSettings viewer={viewer} />
    </Fragment>
  );
};

export default ScenarioOrbitNew;

