import React from 'react'
import { NoDataSvg, NoFileSvg } from './Svgs'
import './EmptyPage.scss'
interface Props {
    content: string;
    content_type?: string;
    children?: React.ReactNode;
}

const EmptyPage = ({ content, content_type = 'contact', children }: Props) => {
    return (
        <div className='empty_ground__contact__container'>
            {children}
            <div className='empty__contact__section'>
                {content_type === 'contact' ? <NoDataSvg /> : <NoFileSvg />}
                <span className='empty__contact__section__content'>{content}</span>
            </div>
        </div>
    )
}

export default EmptyPage