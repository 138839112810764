import './CustomInput.scss'

interface CustomInputProps {
    name?: string;
    placeholder?: string;
    value?: string | number;
    type?: 'text' | 'password' | 'email' | 'number' | 'datetime-local' | 'date' | 'time';
    disabled?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    className?: string;
    required?: boolean;
    min?: number | string;
    max?: number | string;
}

const CustomInput = ({ name,
    placeholder = 'Search',
    value,
    min,
    max,
    type = 'text',
    disabled = false,
    onChange,
    onKeyDown,
    className,
    required = false,
}: CustomInputProps) => {
    return (
        <div className={`custom__input ${className}`}>
            <input
                className='custom__datetime'
                name={name}
                min={min}
                max={max}
                required={required}
                disabled={disabled}
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onKeyDown={onKeyDown}
            />
        </div>
    )
}

export default CustomInput