
import * as Cesium from "cesium";

type Connection = {
  "availability": string,
  "description": string,
  "id": string,
  "name": string,
  "parent": string,
  "polyline": any
}

type Interval = {
  "boolean": boolean,
  "interval": string
}

export const getSatellitePackete = (document: any, satellites: any) => {

  let satelliteData: any[] = [];
  let czmlPacket: any[] = [];

  let description = {
    "description": "Ground Access Metadata Packet",
    "id": "e63330c5-2283-49df-8a23-fa26d92f6ac8",
    "name": "Ground Access"
  }
  czmlPacket.push(document);
  czmlPacket.push(description);
  satellites?.forEach(satellite => {
    if (satellite?.availability) {
      let satellitePackete = {
        "availability": satellite?.availability,
        "billboard": {
          "eyeOffset": {
            "cartesian": [
              0,
              0,
              0
            ]
          },
          "horizontalOrigin": "CENTER",
          "image": require('./cesiumImages/selectedSatellite.png'),
          "pixelOffset": {
            "cartesian2": [
              0,
              0
            ]
          },
          "scale": 0.75,
          "show": true,
          "verticalOrigin": "CENTER"
        },
        "description": "Attached Object to satellite",
        "id": "Satellite/" + satellite?.satId,
        "name": satellite?.satName,
        "path": {
          "leadTime": [
            {
              "number": 5000
            }
          ],
          "width": 1,
          "material": {
            "solidColor": {
              "color": {
                // "rgba": satellite?.isAdditionalObject ? [142, 125, 238, 100] : (satellite?.simulatedOrbit ? [0, 255, 0, 255] : [0, 255, 0, 100])
                "rgba": [138, 138, 138, 40]
              }
            }
          },
          "show": [
            {
              "boolean": true,
              "interval": satellite?.availability
            }
          ],
          "trailTime": [
            {
              "number": 5000
            }
          ]
        },
        "position": {
          "cartographicDegrees": satellite?.satOrbitPoint,
          "epoch": satellite?.availability?.split('/')[0]
        }
      }
      satelliteData.push(satellitePackete)
    }
  })

  if (satelliteData.length > 0) {
    czmlPacket.push(...satelliteData);
  }
  return czmlPacket;
}

export const getOrbitPacket = (satellites: any) => {

  let satelliteData: any[] = [];
  let czmlPacket: any[] = [];

  satellites?.forEach(satellite => {
    let orbitStartTime = satellite.availability?.split('/')[0]
    let orbitEndTime = new Date(new Date(orbitStartTime).getTime() + 1000).toISOString();
    const availability = `${orbitStartTime}/${orbitEndTime}`;
    if (satellite?.availability) {
      let satellitePackete = {
        "availability": availability,
        "description": "Attached extart orbit to satellite orbit",
        "id": satellite?.satId,
        "name": satellite?.satName,
        "path": {
          "width": 2,
          "material": {
            "polylineGlow": {
              "color": {
                "rgba": [240, 240, 240, 240],
              },
              "glowPower": 0.2,
              "taperPower": 0.5,
            },
          },
          "show": [
            {
              "boolean": true,
              "interval": satellite?.availability
            }
          ],
        },
        "position": {
          "cartographicDegrees": satellite?.satOrbitPoint,
          "epoch": satellite?.availability?.split('/')[0]
        }
      }
      satelliteData.push(satellitePackete)
    }
  })


  if (satelliteData.length > 0) {
    czmlPacket.push(
      {
        "clock": {
          interval: satellites[0]?.availability,
          "multiplier": 1,
          "range": "CLAMPED",
          "step": "SYSTEM_CLOCK_MULTIPLIER"
        },
        "id": "document",
        "name": "Orbit_Object",
        "version": "1.0"
      },
      ...satelliteData);
  }
  return czmlPacket;
}


export const getGroundStationPacket = (satellites, groundStations, isGsAccess) => {
  let gsData: any[] = [];

  if (satellites?.length > 0 && groundStations?.length > 0) {
    groundStations?.forEach(station => {

      let stationPackete = {
        "availability": satellites[0]?.availability,
        "billboard": {
          "eyeOffset": {
            "cartesian": [
              0,
              0,
              0
            ]
          },
          "horizontalOrigin": "CENTER",
          "image": '/assets/select_gs.svg',
          "pixelOffset": {
            "cartesian2": [
              0,
              0
            ]
          },
          "scale": 0.8,
          "show": true,
          "verticalOrigin": "CENTER"
        },
        "description": "Ground Station - " + station?.gsId,
        "id": station?.gsId,
        "label": {
          "fillColor": {
            "rgba": [
              255,
              255,
              255,
              255
            ]
          },
          "font": "montserrat",
          "horizontalOrigin": "LEFT",
          "outlineColor": {
            "rgba": [
              0,
              0,
              0,
              255
            ]
          },
          "outlineWidth": 2,
          "pixelOffset": {
            "cartesian2": [
              12,
              0
            ]
          },
          "show": true,
          "style": "FILL_AND_OUTLINE",
          "verticalOrigin": "CENTER"
        },
        "position": {
          "cartographicDegrees": [
            station?.gsPosition?.[0],
            station?.gsPosition?.[1],
            station?.gsPosition?.[2]
          ]
        },
        "ellipse": isGsAccess ? {
          "semiMinorAxis": 900000, // Adjust the value based on the desired size
          "semiMajorAxis": 900000, // Adjust the value based on the desired size
          "material": {
            "solidColor": {
              "color": {
                "rgba": [
                  204,
                  245,
                  78,
                  0.40
                ]
              }
            }
          },
          "height": 0,
          "extrudedHeight": 0,
          "rotation": 0,
          "stRotation": 0,
          "fill": true,
        } : ''
      }
      gsData.push(stationPackete);
    })
  }
  return gsData;
}

export const getAoiPacket = (satellites, aois) => {
  let aoiData: any[] = [];
  if (satellites?.length > 0 && aois?.length > 0) {
    aois?.forEach((aoi, index) => {
      let aoiPacket = {
        "availability": satellites[0].availability,
        "description": "area of interest - " + index,
        "id": "Facility/aoi/" + index,
        "name": (aoi?.name ? aoi?.name : aoi?.aoiName),
        "polygon": {
          "extrudedHeight": 0,
          "height": 0,
          "material": {
            "solidColor": {
              "color": {
                "rgba": [
                  204, 245, 78, 50
                ]
              }
            }
          },
          "arcType": "RHUMB",
          "positions": {
            "cartographicDegrees": aoi.polygon ? getCartographicDegrees(aoi?.polygon) : aoi?.aoiPosition
          }
        }
      }
      aoiData.push(aoiPacket)
    })
  }
  return aoiData;
}

export const getGroundTargetPacket = (satellites, groundTargets) => {
  let groundTargetData: any[] = [];
  if (satellites?.length > 0 && groundTargets?.length > 0) {
    groundTargets?.forEach((groundTarget, index) => {
      let gtPacket = {
        "availability": satellites[0]?.availability,
        "billboard": {
          "eyeOffset": {
            "cartesian": [
              0,
              0,
              0
            ]
          },
          "horizontalOrigin": "CENTER",
          "image": require('./cesiumImages/gsTarget.png'),
          "pixelOffset": {
            "cartesian2": [
              0,
              0
            ]
          },
          "scale": 1,
          "show": true,
          "verticalOrigin": "CENTER"
        },
        "description": "Ground Target - " + index,
        "id": groundTarget?.groundTargetId,
        "label": {
          "fillColor": {
            "rgba": [
              255,
              255,
              255,
              255
            ]
          },
          "font": "montserrat",
          "horizontalOrigin": "LEFT",
          "outlineColor": {
            "rgba": [
              0,
              0,
              0,
              255
            ]
          },
          "outlineWidth": 2,
          "pixelOffset": {
            "cartesian2": [
              12,
              0
            ]
          },
          "show": true,
          "style": "FILL_AND_OUTLINE",
          "verticalOrigin": "CENTER"
        },
        "name": "0dcfed5e-7868-4470-9fb3-068da73a5c7a",
        "position": {
          "cartographicDegrees": (groundTarget?.coordinates?.long) ?
            [
              groundTarget?.coordinates?.long,
              groundTarget?.coordinates?.lat,
              0
            ] : [
              groundTarget?.position?.[0],
              groundTarget?.position?.[1],
              groundTarget?.position?.[2]
            ]
        }
      }
      groundTargetData.push(gtPacket)
    })
  }
  return groundTargetData;
}

//Ground Station to Satellite Connection
export const groundStationToSatelliteConnection = (satToGSConnection) => {
  let gsToSatConnection: Connection[] = [];

  satToGSConnection?.forEach(gsToSat => {
    let interval: Interval[] = [];

    gsToSat?.availability?.map((item, key) => {
      let intervalPacket = {
        "boolean": true,
        "interval": item
      }
      interval?.push(intervalPacket)
    })

    let gsToSatPackete = {
      "availability": gsToSat?.availability,
      "description": "Ground Station to Satellite Connection",
      "id": gsToSat?.id,
      "name": gsToSat?.name,
      "parent": "e63330c5-2283-49df-8a23-fa26d92f6ac8",
      "polyline": {
        "arcType": "NONE",
        "material": {
          "solidColor": {
            "color": {
              "rgba": [
                0,
                255,
                255,
                255
              ]
            }
          }
        },
        "positions": {
          "references": [
            gsToSat?.groundStationId + "#position",
            "Satellite/" + gsToSat?.satelliteId + "#position"
          ]
        },
        "show": interval,
        "width": 1
      }
    }
    gsToSatConnection.push(gsToSatPackete)
  })

  return gsToSatConnection
}

//Ground Station to Satellite Connection
export const satelliteToGroundTargetConnection = (satToGroundTarget) => {
  let gsToGtConnection: Connection[] = [];

  satToGroundTarget?.forEach((gtToSat, index) => {
    let interval: Interval[] = [];

    gtToSat?.availability?.map((item, key) => {
      let intervalPacket = {
        "boolean": true,
        "interval": item
      }
      interval.push(intervalPacket)
    })

    let gsToSatPackete = {
      "availability": gtToSat?.availability,
      "description": "Ground Target to Satellite Connection",
      "id": gtToSat?.id + "/" + index,
      "name": gtToSat?.name,
      "parent": "e63330c5-2283-49df-8a23-fa26d92f6ac8",
      "polyline": {
        "arcType": "NONE",
        "material": {
          "solidColor": {
            "color": {
              "rgba": [
                0,
                255,
                255,
                255
              ]
            }
          }
        },
        "positions": {
          "references": [
            gtToSat?.groundTargetId + "#position",
            "Satellite/" + gtToSat?.satelliteId + "#position"
          ]
        },
        "show": interval,
        "width": 1
      }
    }
    index++;
    gsToGtConnection.push(gsToSatPackete)
  })

  return gsToGtConnection
}

const getCartographicDegrees = (position) => {
  let cartographicDegrees: any[] = [];
  position.forEach((element: any) => {
    cartographicDegrees.push(element[0]);
    cartographicDegrees.push(element[1]);
    cartographicDegrees.push(0);
  });
  return cartographicDegrees;
}

export function removeDataSourceByName(viewer: any, dataSourceName: string) {
  const dataSources = viewer?.dataSources;
  if (!dataSources) return
  for (let i = 0; i < dataSources.length; i++) {
    const dataSource = dataSources.get(i);
    if (dataSource.name === dataSourceName) {
      dataSources.remove(dataSource);
      return dataSource;
    }
  }
}

export function getDataSourceByName(viewer: any, dataSourceName: string) {
  const dataSources = viewer?.dataSources;
  if (!dataSources) return
  for (let i = 0; i < dataSources.length; i++) {
    const dataSource = dataSources.get(i);
    if (dataSource.name === dataSourceName) {
      return dataSource;
    }
  }
}

export const update_sat_orbit_path = (viewer: Cesium.Viewer | null, current_time: number) => {
  const data_source = getDataSourceByName(viewer, 'Orbit_Object')
  if (data_source) {
    data_source.entities.values.forEach(entity => {
      if (entity) {
        const start_time = Cesium.JulianDate.fromDate(new Date(current_time - 10 * 60000));
        const end_time = Cesium.JulianDate.fromDate(new Date(current_time + 2000));
        const availability = new Cesium.TimeIntervalCollection([
          new Cesium.TimeInterval({
            start: start_time,
            stop: end_time
          })
        ]);
        entity.availability = availability;
      }
    });
  }
}