
import './Planning.scss'
import OnboardSchedule from './schedule/OnboardSchedule'
import GroundContact from './ground_contact/GroundContact'
import useUrlParams from '../../../hooks/useUrlParams'
import CustomLoader from '../../../custom_components/CustomLoader';
import useTimeline from '../../../hooks/useTimeline';
import ControlSchedule from './schedule/ControlSchedule';


const Planning = () => {

    const { tab_name } = useUrlParams()
    const { loading } = useTimeline()
    return (
        <div className='planning__container__wrapper'>
            <div className='planning__container'>
                {loading &&
                    <div className='loading__container'>
                        <CustomLoader loading={loading} />
                    </div>
                }
                {tab_name === 'plan-onboard-schedule' && <OnboardSchedule />}
                {tab_name === 'plan-control-schedule' && <ControlSchedule />}
                {tab_name === 'plan-ground-contact' && <GroundContact />}
            </div>
        </div>
    )
}

export default Planning