// @ts-nocheck
import { Button } from 'react-bootstrap';
import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { CircularProgress } from '@mui/material';
import { MissionStore, setCloneTemplate } from '../../../../../store/MissionSlice';
import CatalogueService from '../../../../../service/CatalogueService';
import AddDevice from './AddDevice';

const BusSlider = ({ closeBusSlider, busSlider }) => {

    const dispatch = useDispatch();
    const sliderSelect = busSlider;
    const isCloneTemplate = useSelector((state: { mission: MissionStore }) => state.mission.isCloneTemplate);
    const isNewTemplate = useSelector((state: { mission: MissionStore }) => state.mission.isNewTemplate);


    // {/* ////...........................................................//// */}

    const NavigatorItems = [
        {
            name: 'Integrated',
        },
        {
            name: 'Discrete',
        }
    ]
    const [navigator, setNavigator] = useState((sliderSelect.type === 'ADCS' || sliderSelect.type === 'IMU') ? 'Discrete' : 'Integrated')

    const [getIntegratedData, setIntegratedData] = useState<any>([]);
    const [selectedIntegratedId, setSelectedIntegratedId] = useState<any>('');
    const [isIntegratedData, setIsIntegratedData] = useState<any>({});

    const [getDiscreteAdcsData, setDiscreteAdcsData] = useState<any>([]);
    const [adcsKeys, setAdcsKeys] = useState<any>({});
    const [isAdcsKeys, setIsAdcsKeys] = useState<any>(false);
    const [selectedDiscreteData, setSelectedDiscreteData] = useState<any>({});
    const [isSelectedDiscreteData, setIsSelectedDiscreteData] = useState<any>(false);
    const [selectedDiscreteId, setSelectedDiscreteId] = useState<any>();

    const [isIntegratedIMUData, setIsIntegratedIMUData] = useState<any>({});
    const [busOSData, setBusOSData] = useState<any>({});
    const [busOBCData, setBusOBCData] = useState<any>({});

    const [imuKeys, setImuKeys] = useState<any>({});
    const [isImuKeys, setIsImuKeys] = useState<any>(false);
    const [selectedIMUData, setSelectedIMUData] = useState<any>({});

    const CloneTemplate = useSelector((state: { mission: MissionStore }) => state.mission.CloneTemplate);
    const Satellite = useSelector((state: { mission: MissionStore }) => state.mission.Satellite);
    const [selectedTemplateData, setSelectedTemplateData] = useState<any>({});

    const [totalCount, setTotalCount] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [isCountBtnDisabled, setIsCountBtnDisabled] = useState<boolean>(false);

    const [add_new_device, set_add_new_device] = useState<any>(false)

    useEffect(() => {
        if (isCloneTemplate || isNewTemplate) {
            if (selectedIntegratedId === '' || selectedIntegratedId === undefined) {
                setTotalCount(0)
            } else {
                if ((selectedIntegratedId === sliderSelect?.['data']?.['name']) && isCloneTemplate) {
                    setTotalCount(sliderSelect.numUnits)
                } else {
                    setTotalCount(1)
                }
            }
        }
    }, [selectedIntegratedId])

    const [expanded, setExpanded] = React.useState('');
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : '');
        set_add_new_device(!isExpanded && false)
        setDiscreteAdcsData([])
    };

    const handleCancel = () => {
        closeBusSlider()
        setSelectedTemplateData({})
        setSelectedIntegratedId('')
    }

    useEffect(() => {
        set_add_new_device(false)
        if (sliderSelect.type === 'ADCS' || sliderSelect.type === 'IMU') {
            setNavigator('Discrete')
        } else if (sliderSelect.type === 'iADCS' || sliderSelect.type === 'iIMU') {
            setNavigator('Integrated')
        }
    }, [sliderSelect.type])

    useEffect(() => {
        if (isCloneTemplate || isNewTemplate) {
            if (Object.keys(Satellite?.Template)?.length > 0) {
                const d = Satellite?.Template;
                setIsIntegratedData(CloneTemplate?.['Core']?.['iADCS'] ? CloneTemplate?.['Core']?.['iADCS'] : d['assembly'][0]['iADCS']['atmos-data'])
                setAdcsKeys(CloneTemplate?.['Core']?.['ADCS'] ? CloneTemplate?.['Core']?.['ADCS'] : d['assembly'][0]['core']['ADCS'])
                // setIsIntegratedIMUData(CloneTemplate?.['Core']?.['iIMU'] ? CloneTemplate?.['Core']?.['iIMU'] : d['assembly'][0]?.['core']?.['iIMU']['atmos-data'])
                // setImuKeys(CloneTemplate?.['Core']?.['IMU'] ? CloneTemplate?.['Core']?.['IMU'] : d['assembly']?.[0]?.['core']?.['IMU'])
                setBusOSData(CloneTemplate?.['Core']?.['Bus OS'] ? CloneTemplate?.['Core']?.['Bus OS'] : d['assembly']?.[0]?.['core']?.['Bus OS']['atmos-data'])
                setBusOBCData(CloneTemplate?.['Core']?.['Bus OBC'] ? CloneTemplate?.['Core']?.['Bus OBC'] : d['assembly']?.[0]?.['core']?.['Bus OBC']['atmos-data'])
                setIsAdcsKeys(true)
                setIsImuKeys(true)
            }
        }

    }, [isCloneTemplate, isNewTemplate, CloneTemplate['Core']])


    useEffect(() => {
        if ((isCloneTemplate || isNewTemplate) && sliderSelect.type !== 'Bus OBC') {
            setLoading(true)
            setIntegratedData([])
            let response = CatalogueService.getCatalogue(sliderSelect.subSystem, (((sliderSelect.type === 'ADCS' || sliderSelect.type === 'iADCS') && (navigator === 'Integrated' || navigator === 'Discrete')) ? 'iADCS' : ((sliderSelect.type === 'IMU' || sliderSelect.type === 'iIMU') && (navigator === 'Integrated' || navigator === 'Discrete')) ? 'iIMU' : sliderSelect.type));
            response?.then(data => {
                if (data?.data) {
                    let filterdData = data.data.filter(item => item.name !== ((sliderSelect.type === 'ADCS' && navigator === 'Integrated') ? isIntegratedData?.['name'] : (sliderSelect.type === 'IMU' && navigator === 'Integrated') ? isIntegratedIMUData?.['name'] : sliderSelect['data']['name']))
                    setIntegratedData(filterdData)
                    setSelectedIntegratedId((sliderSelect.type === 'ADCS' && navigator === 'Integrated') ? isIntegratedData?.['name'] : (sliderSelect.type === 'IMU' && navigator === 'Integrated') ? isIntegratedIMUData?.['name'] : sliderSelect['data']['name'])
                }
                setLoading(false)
            }).catch(e => {
                console.error(e);
                setLoading(false)
            })
        }
    }, [isCloneTemplate, sliderSelect.type, sliderSelect.subSystem, navigator])


    useEffect(() => {
        if (Object.keys(adcsKeys).length > 0 && (isCloneTemplate || isNewTemplate) && isAdcsKeys && (sliderSelect.type === 'ADCS' || sliderSelect.type === 'iADCS')) {
            setSelectedDiscreteData({
                'Horizon Sensor': adcsKeys?.['Horizon Sensor']?.['atmos-data'] ? adcsKeys?.['Horizon Sensor']?.['atmos-data'] : adcsKeys?.['Horizon Sensor'],
                'Star Sensor': adcsKeys?.['Star Sensor']?.['atmos-data'] ? adcsKeys?.['Star Sensor']?.['atmos-data'] : adcsKeys?.['Star Sensor'],
                'Sun-n-Nadir Sensor': adcsKeys?.['Sun-n-Nadir Sensor']?.['atmos-data'] ? adcsKeys?.['Sun-n-Nadir Sensor']?.['atmos-data'] : adcsKeys?.['Sun-n-Nadir Sensor'],
                'Torquer': adcsKeys?.['Torquer']?.['atmos-data'] ? adcsKeys?.['Torquer']?.['atmos-data'] : adcsKeys?.['Torquer'],
                'Wheels': adcsKeys?.['Wheels']?.['atmos-data'] ? adcsKeys?.['Wheels']?.['atmos-data'] : adcsKeys?.['Wheels'],
            })
            setIsAdcsKeys(false)
            setIsImuKeys(true)
        }

    }, [isAdcsKeys, sliderSelect.type])

    useEffect(() => {
        // debugger
        if ((isCloneTemplate || isNewTemplate)) {
            // setSelectedIMUData({
            //     'Accelerometer': imuKeys?.['Accelerometer']?.['atmos-data'] ? imuKeys?.['Accelerometer']?.['atmos-data'] : imuKeys?.['Accelerometer'],
            //     'Gyro': imuKeys?.['Gyro']?.['atmos-data'] ? imuKeys?.['Gyro']?.['atmos-data'] : imuKeys?.['Gyro'],
            //     'Magnetometer': imuKeys?.['Magnetometer']?.['atmos-data'] ? imuKeys?.['Magnetometer']?.['atmos-data'] : imuKeys?.['Magnetometer'],
            // })
            setIsAdcsKeys(true)
            // setIsImuKeys(false)
        }

    }, [isImuKeys, sliderSelect.type])

    const getAdcsDiscreteData = (key: string) => {
        setLoading(true)
        if ((isCloneTemplate || isNewTemplate) && Object.keys((sliderSelect.type === 'ADCS' || sliderSelect.type === 'iADCS') ? selectedDiscreteData : selectedIMUData).length > 0 && key) {
            const response = CatalogueService.getCatalogue("Core", key);
            response?.then(data => {
                if (data.data) {
                    const filterdData = data.data.filter(item => item.name !== ((sliderSelect.type === 'ADCS' || sliderSelect.type === 'iADCS') ? selectedDiscreteData?.[`${key}`]?.['name'] : selectedIMUData?.[`${key}`]?.['name']))
                    setDiscreteAdcsData(filterdData)
                    setSelectedDiscreteId((sliderSelect.type === 'ADCS' || sliderSelect.type === 'iADCS') ? selectedDiscreteData?.[`${key}`]?.['name'] : selectedIMUData?.[`${key}`]?.['name'])
                }
                setLoading(false)
            }).catch(e => {
                console.error(e);
                setLoading(false)
            })
        }

    }


    const handleIntegratedCloneData = (data: any) => {
        if (navigator === 'Integrated' && (sliderSelect.type === 'ADCS' || sliderSelect.type === 'iADCS')) {
            const result = {
                'iADCS': data,
                'adcsType': navigator
            }
            setSelectedTemplateData(result)
        } else if (navigator === 'Integrated' && (sliderSelect.type === 'IMU' || sliderSelect.type === 'iIMU')) {
            const result = {
                'iIMU': data,
                'imuType': navigator
            }
            setSelectedTemplateData(result)
        }
    }
    useEffect(() => {
        if (navigator === 'Discrete' && isSelectedDiscreteData && (sliderSelect.type === 'ADCS' || sliderSelect.type === 'iADCS')) {
            const result = {
                'ADCS': selectedDiscreteData,
                'adcsType': navigator
            }
            setSelectedTemplateData(result)
            setIsSelectedDiscreteData(false)
        } else if (navigator === 'Discrete' && isSelectedDiscreteData && (sliderSelect.type === 'IMU' || sliderSelect.type === 'iIMU')) {
            const result = {
                'IMU': selectedIMUData,
                'imuType': navigator
            }
            setSelectedTemplateData(result)
            setIsSelectedDiscreteData(false)
        }
    }, [isSelectedDiscreteData, sliderSelect.type])


    const handleSubmitCloneTemplate = () => {
        if (isCloneTemplate || isNewTemplate) {
            if (Object.keys(selectedTemplateData).length > 0) {
                if (sliderSelect.type === 'Solar Panel' || sliderSelect.type === 'Payload SSD' || sliderSelect.type === 'Temperature Sensor' || sliderSelect.type === 'Heater' || sliderSelect.type === 'Thruster') {
                    const data = ({ ...selectedTemplateData, [sliderSelect.type + 'Count']: totalCount })
                    dispatch(setCloneTemplate({ [sliderSelect.subSystem]: data }))
                } else {
                    dispatch(setCloneTemplate({ [sliderSelect.subSystem]: selectedTemplateData }))
                }
            } else {
                if (sliderSelect.type === 'Solar Panel' || sliderSelect.type === 'Payload SSD' || sliderSelect.type === 'Temperature Sensor' || sliderSelect.type === 'Heater' || sliderSelect.type === 'Thruster') {
                    const data = ({ [sliderSelect.type]: sliderSelect?.['data'], [sliderSelect.type + 'Count']: totalCount })
                    dispatch(setCloneTemplate({ [sliderSelect.subSystem]: data }))
                } else {
                    const data = ({ [sliderSelect.type]: sliderSelect?.['data'] })
                    dispatch(setCloneTemplate({ [sliderSelect.subSystem]: data }))
                }
            }
            handleCancel()
        } else {
            handleCancel()
        }
    }


    // {/* ////...........................................................//// */}

    const getOtherTemplateData = (key: string) => {
        setLoading(true)
        setIntegratedData([])
        CatalogueService.getCatalogue("Core", key)?.then(data => {
            if (data) {
                const filterdData = data.data.filter(item => item.name !== (key === 'Bus OBC' ? busOBCData?.['name'] : busOSData?.['name']))
                setIntegratedData(filterdData)
                setSelectedIntegratedId(key === 'Bus OBC' ? busOBCData?.['name'] : busOSData?.['name'])
            }
            setLoading(false)
        }).catch(e => {
            console.error(e);
            setLoading(false)
        })
    }

    const add_new_device_details = (data: any) => {
        setIntegratedData(prev => [...prev, data])
        set_add_new_device(false)
    }

    // {/* ////...........................................................//// */}

    return <div className="right-slider-box config-slider edge-cards">
        <div className="slider-header">
            <h4>{sliderSelect.type}</h4>
        </div>

        {(!isCloneTemplate && !isNewTemplate) && <div className="slider-content">
            <span className="sm-title">{(sliderSelect.type === 'ADCS' || sliderSelect.type === 'IMU') ? 'Discrete' : ''}</span>
            {sliderSelect.type !== 'ADCS' && sliderSelect.type !== 'IMU' &&
                <div className="common-card-box active">
                    <div className="common-card-content">
                        <span className='fs-18 color-text'>
                            {sliderSelect.data?.['name']?.length > 30 ? sliderSelect.data?.['name'].slice(0, 30) + '...' : sliderSelect.data?.['name']}
                        </span>
                        {<div className="d-flex flex-wrap">
                            {sliderSelect.type === 'Payload' && <>
                                <div className="stats-alpha w-50">
                                    <strong>{sliderSelect?.data?.['component_specific_attributes']['payloadType']}</strong>
                                    <span>Payload Type</span>
                                </div>
                                {sliderSelect?.data?.['component_specific_attributes']['payloadSubType'] && <div className="stats-alpha w-50">
                                    <strong>{sliderSelect?.data?.['component_specific_attributes']['payloadSubType']}</strong>
                                    <span>Payload SubType</span>
                                </div>}
                                {sliderSelect?.data?.['component_specific_attributes']['payloadDetails'] && <div className="stats-alpha w-50">
                                    <strong>{sliderSelect?.data?.['component_specific_attributes']['payloadDetails']}</strong>
                                    <span>Payload Details</span>
                                </div>}

                                {sliderSelect?.data?.['component_specific_attributes']['typeSpecificParams'] &&
                                    Object.keys(sliderSelect?.data?.['component_specific_attributes']['typeSpecificParams'])?.length > 0 &&
                                    Object.keys(sliderSelect?.data?.['component_specific_attributes']['typeSpecificParams']).map((k, i) => {
                                        return (
                                            <div key={i} className="stats-alpha w-50">
                                                {k !== 'Frequency Bands' && <strong>
                                                    {k === 'GSD' ?
                                                        (sliderSelect?.data?.['component_specific_attributes']['typeSpecificParams'][k] + ' m') :
                                                        (k === 'Swath' ? (sliderSelect?.data?.['component_specific_attributes']['typeSpecificParams'][k] + ' km') :
                                                            sliderSelect?.data?.['component_specific_attributes']['typeSpecificParams'][k])}
                                                </strong>}
                                                {k === 'Frequency Bands' &&
                                                    <div className='d-flex flex-wrap flex-1'>
                                                        {sliderSelect?.data?.['component_specific_attributes']['typeSpecificParams'][k].map((band, index) =>
                                                            <div className='d-flex gap-1' key={index}>
                                                                <strong>{band}</strong>
                                                                {index !== (sliderSelect?.data?.['component_specific_attributes']['typeSpecificParams'][k].length - 1) && <strong className='pe-1 opacity-50'>|</strong>}
                                                            </div>
                                                        )}
                                                    </div>
                                                }
                                                <span>{k}</span>
                                            </div>
                                        )
                                    })}
                                {sliderSelect?.data?.['component_specific_attributes']['additionalParams'] &&
                                    Object.keys(sliderSelect?.data?.['component_specific_attributes']['additionalParams'])?.length > 0 &&
                                    Object.keys(sliderSelect?.data?.['component_specific_attributes']['additionalParams']).map((k, i) => {
                                        return (
                                            <div key={i} className="stats-alpha w-50">
                                                <strong>{sliderSelect?.data?.['component_specific_attributes']['additionalParams'][k]}</strong>
                                                <span>{k}</span>
                                            </div>
                                        )
                                    })}
                            </>}

                            {sliderSelect?.data && Object.keys(sliderSelect?.data?.["product-cookie"])?.length > 0 &&
                                Object.keys(sliderSelect?.data?.["product-cookie"]).filter(k => k !== 'friendly-name').map((k, i) => {
                                    return <div key={i} className="stats-alpha w-50">
                                        <strong>{sliderSelect.data?.["product-cookie"][k]}{k === 'Size' ? ' U' : ''}</strong>
                                        <span>{k}</span>
                                    </div>
                                })}
                        </div>}

                        {sliderSelect.type === 'iADCS' &&
                            sliderSelect.data && sliderSelect.data["component_specific_attributes"] &&
                            sliderSelect.data["component_specific_attributes"]["Components"] &&
                            sliderSelect.data["component_specific_attributes"]["Components"].map((c, i) => {
                                return <div key={i} className="common-card-box active sub-box">
                                    <div className="common-card-content">
                                        {c?.['numUnits'] !== '' && <div>{c?.['friendly-name']} ({c?.['numUnits']})</div>}
                                        <div className="d-flex flex-wrap">
                                            {Object.keys(c).filter(k => k !== 'friendly-name' &&
                                                k !== 'description' &&
                                                k !== 'numUnits').map((k, j) => {
                                                    return <div key={j} className="stats-alpha">
                                                        <strong>{c[k]}</strong>
                                                        <span>{k}</span>
                                                    </div>
                                                })}
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            }

            {(sliderSelect.type === 'ADCS' || sliderSelect.type === 'IMU') &&
                Object.keys(sliderSelect.data).map((c) => {
                    return <div key={sliderSelect.data[c]['atmos-data']?.['name']} className="common-card-box active">
                        <div className="common-card-content">
                            <div>
                                {sliderSelect.data[c]['atmos-data']?.['name']}
                            </div>
                            <div className="d-flex flex-wrap">
                                {sliderSelect.data[c]['atmos-data'] &&
                                    Object.keys(sliderSelect.data[c]['atmos-data']?.["product-cookie"]).filter(
                                        k => k !== 'friendly-name').map((k, i) => {
                                            return <div key={i} className="stats-alpha">
                                                <strong>{sliderSelect.data[c]['atmos-data']?.["product-cookie"][k]}</strong>
                                                <span>{k}</span>
                                            </div>
                                        })}
                            </div>
                        </div>
                    </div>
                })}

            {(sliderSelect?.['data']?.['antenna'] && typeof sliderSelect?.['data']?.['antenna'] === 'object') &&
                <div className="common-card-box active">
                    <div className="common-card-content">
                        <div className='fs-18 opacity-75'>{sliderSelect.data?.['antenna']['friendly-name']}</div>
                        <div className="d-flex flex-wrap">
                            {Object.keys(sliderSelect.data?.['antenna']).map(k => {
                                if (k !== 'friendly-name') {
                                    return (
                                        <div className="stats-alpha" key={k}>
                                            <strong>{sliderSelect.data?.['antenna'][k]}</strong>
                                            <span>{k}</span>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </div>
                </div>
            }
        </div>}

        {(!isCloneTemplate && !isNewTemplate) || ((sliderSelect.type === 'Edge Device' || sliderSelect.type === 'Edge SSD' || sliderSelect.type === 'Payload')) &&
            <div className="slider-content">
                <span className="sm-title">{(sliderSelect.type === 'ADCS' || sliderSelect.type === 'IMU') ? 'Discrete' : ''}</span>
                {sliderSelect.type !== 'ADCS' && sliderSelect.type !== 'IMU' &&
                    <div className="common-card-box active">
                        <div className="common-card-content">
                            <div>
                                {sliderSelect.data?.['name']}
                            </div>
                            {<div className="d-flex flex-wrap">
                                {sliderSelect.type === 'Payload' && <>
                                    <div className="stats-alpha">
                                        <strong>{sliderSelect?.data?.['component_specific_attributes']['payloadType']}</strong>
                                        <span>Category</span>
                                    </div>
                                    {sliderSelect?.data?.['component_specific_attributes']['payloadSubType'] && <div className="stats-alpha">
                                        <strong>{sliderSelect?.data?.['component_specific_attributes']['payloadSubType']}</strong>
                                        <span>Payload Type</span>
                                    </div>}
                                    {sliderSelect?.data?.['component_specific_attributes']['payloadDetails'] && <div className="stats-alpha">
                                        <strong>{sliderSelect?.data?.['component_specific_attributes']['payloadDetails']}</strong>
                                        <span>Payload SubType</span>
                                    </div>}

                                    {sliderSelect?.data?.['component_specific_attributes']['typeSpecificParams'] &&
                                        Object.keys(sliderSelect?.data?.['component_specific_attributes']['typeSpecificParams'])?.length > 0 &&
                                        Object.keys(sliderSelect?.data?.['component_specific_attributes']['typeSpecificParams']).map((k, i) => {
                                            return (
                                                <div key={i} className="stats-alpha">
                                                    <strong>{k === 'GSD' ?
                                                        (sliderSelect?.data?.['component_specific_attributes']['typeSpecificParams'][k] + ' m') :
                                                        (k === 'Swath' ? (sliderSelect?.data?.['component_specific_attributes']['typeSpecificParams'][k] + ' km') :
                                                            sliderSelect?.data?.['component_specific_attributes']['typeSpecificParams'][k])}</strong>
                                                    <span>{k}</span>
                                                </div>
                                            )
                                        })}
                                    {sliderSelect?.data?.['component_specific_attributes']['additionalParams'] &&
                                        Object.keys(sliderSelect?.data?.['component_specific_attributes']['additionalParams'])?.length > 0 &&
                                        Object.keys(sliderSelect?.data?.['component_specific_attributes']['additionalParams']).map((k, i) => {
                                            return (
                                                <div key={i} className="stats-alpha">
                                                    <strong>{sliderSelect?.data?.['component_specific_attributes']['additionalParams'][k]}</strong>
                                                    <span>{k}</span>
                                                </div>
                                            )
                                        })}
                                </>}

                                {sliderSelect?.data && Object.keys(sliderSelect?.data?.["product-cookie"])?.length > 0 &&
                                    Object.keys(sliderSelect?.data?.["product-cookie"]).filter(k => k !== 'friendly-name').map((k, i) => {
                                        return <div key={i} className="stats-alpha">
                                            <strong>{sliderSelect.data?.["product-cookie"][k]}{k === 'Size' ? ' U' : ''}</strong>
                                            <span>{k}</span>
                                        </div>
                                    })}
                            </div>}

                            {sliderSelect.type === 'iADCS' &&
                                sliderSelect.data && sliderSelect.data["component_specific_attributes"] &&
                                sliderSelect.data["component_specific_attributes"]["Components"] &&
                                sliderSelect.data["component_specific_attributes"]["Components"].map((c, i) => {
                                    return <div key={i} className="common-card-box active sub-box">
                                        <div className="common-card-content">
                                            {c?.['numUnits'] !== '' && <div>{c?.['friendly-name']} ({c?.['numUnits']})</div>}
                                            <div className="d-flex flex-wrap">
                                                {Object.keys(c).filter(k => k !== 'friendly-name' &&
                                                    k !== 'description' &&
                                                    k !== 'numUnits').map((k, j) => {
                                                        return <div key={j} className="stats-alpha">
                                                            <strong>{c[k]}</strong>
                                                            <span>{k}</span>
                                                        </div>
                                                    })}
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                }

                {(sliderSelect.type === 'ADCS' || sliderSelect.type === 'IMU') &&
                    Object.keys(sliderSelect.data).map((c) => {
                        return <div key={sliderSelect.data[c]['atmos-data']?.['name']} className="common-card-box active">
                            <div className="common-card-content">
                                <div>
                                    {sliderSelect.data[c]['atmos-data']?.['name']}
                                </div>
                                <div className="d-flex flex-wrap">
                                    {sliderSelect.data[c]['atmos-data'] &&
                                        Object.keys(sliderSelect.data[c]['atmos-data']?.["product-cookie"]).filter(
                                            k => k !== 'friendly-name').map((k, i) => {
                                                return <div key={i} className="stats-alpha">
                                                    <strong>{sliderSelect.data[c]['atmos-data']?.["product-cookie"][k]}</strong>
                                                    <span>{k}</span>
                                                </div>
                                            })}
                                </div>
                            </div>
                        </div>
                    })}

                {(sliderSelect?.['data']?.['antenna'] && typeof sliderSelect?.['data']?.['antenna'] === 'object') &&
                    <div className="common-card-box active">
                        <div className="common-card-content">
                            <div className="d-flex flex-wrap">
                                {Object.keys(sliderSelect.data?.['antenna']).map(k => (
                                    <div className="stats-alpha" key={k}>
                                        <strong>{sliderSelect.data?.['antenna'][k]}</strong>
                                        <span>{k === 'friendly-name' ? 'Name' : k}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                }
            </div>
        }

        {/* ////...........................................................//// */}


        {/* ///////////////////..............CLONE OR NEW TEMPLATE..................////////////////////// */}
        {(isCloneTemplate || isNewTemplate) && (sliderSelect.type === 'ADCS' || sliderSelect.type === 'iADCS' || sliderSelect.type === 'IMU' || sliderSelect.type === 'iIMU') && <>
            <div style={{ display: 'flex', alignSelf: 'center', justifyContent: 'center', textAlign: 'center', width: '100%', height: '50px', marginTop: '10px' }}>
                {NavigatorItems.map(item => (
                    <span onClick={() => { setNavigator(item.name) }} key={item.name}
                        style={{
                            display: 'flex', width: '200px', fontWeight: navigator === item.name ? 600 : 500, height: '40px', justifyContent: 'center', alignItems: 'center',
                            background: navigator === item.name ? '#20563d' : 'rgba(0,0,0,0.1)', borderRadius: '5px', cursor: 'pointer'
                        }}
                    >
                        {item.name}
                    </span>
                ))}
            </div>
            <div className="slider-content">
                {(navigator === 'Integrated' && !add_new_device) && <>
                    {(Object.keys((sliderSelect.type === 'iADCS' || sliderSelect.type === 'ADCS') ? isIntegratedData : isIntegratedIMUData).length > 0 && sliderSelect?.['data']?.['name']) &&
                        <>
                            <h4 style={{ display: 'flex', marginBottom: '10px' }}>{isNewTemplate ? 'Selected Template' : 'Existing Component '}</h4>
                            <div className={selectedIntegratedId === ((sliderSelect.type === 'iADCS' || sliderSelect.type === 'ADCS') ? isIntegratedData?.['name'] : isIntegratedIMUData?.['name']) ? 'common-card-box active' : "common-card-box"}
                                onClick={() => {
                                    setSelectedIntegratedId((sliderSelect.type === 'iADCS' || sliderSelect.type === 'ADCS') ? isIntegratedData?.['name'] : isIntegratedIMUData?.['name'])
                                    handleIntegratedCloneData((sliderSelect.type === 'iADCS' || sliderSelect.type === 'ADCS') ? isIntegratedData : isIntegratedIMUData)
                                }}>
                                <div className="common-card-content">
                                    <div>
                                        {(sliderSelect.type === 'iADCS' || sliderSelect.type === 'ADCS') ? isIntegratedData?.['name'] : isIntegratedIMUData?.['name']}
                                    </div>
                                    <div className="d-flex flex-wrap">
                                        {Object.keys((sliderSelect.type === 'iADCS' || sliderSelect.type === 'ADCS') ? isIntegratedData?.["product-cookie"] : isIntegratedIMUData?.["product-cookie"]).filter(
                                            k => k !== 'friendly-name').map((k) => (
                                                <div key={k} className="stats-alpha">
                                                    <strong>{(sliderSelect.type === 'iADCS' || sliderSelect.type === 'ADCS') ? isIntegratedData?.["product-cookie"][k] : isIntegratedIMUData?.["product-cookie"][k]}</strong>
                                                    <span>{k}</span>
                                                </div>
                                            ))}
                                        {/* <div className="stats-alpha">
                                        <strong>{(sliderSelect.type === 'iADCS' || sliderSelect.type === 'ADCS') ? isIntegratedData?.['weight_in_gms'] : isIntegratedIMUData?.['weight_in_gms']}</strong>
                                        <span>Weight (Gms)</span>
                                    </div>
                                    <div className="stats-alpha">
                                        <strong>{(sliderSelect.type === 'iADCS' || sliderSelect.type === 'ADCS') ? isIntegratedData?.['max_power_consumption_in_watts'] : isIntegratedIMUData?.['max_power_consumption_in_watts']} (W)</strong>
                                        <span>Peak Power</span>
                                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    <h4 style={{ display: 'flex', marginBottom: '10px' }}>{getIntegratedData && getIntegratedData?.length === 0 && 'No '} Available Components </h4>

                    {getIntegratedData?.length > 0 && getIntegratedData.map(data => (
                        <div key={data.name} className={selectedIntegratedId === data.name ? 'common-card-box active' : "common-card-box"}
                            onClick={() => {
                                setSelectedIntegratedId(data.name)
                                handleIntegratedCloneData(data)
                            }}>
                            <div className="common-card-content">
                                <div>
                                    {data.name}
                                </div>
                                <div className="d-flex flex-wrap">
                                    {Object.keys(data?.["product-cookie"]).filter(
                                        k => k !== 'friendly-name').map((k) => (
                                            <div key={k} className="stats-alpha">
                                                <strong>{data["product-cookie"][k]}</strong>
                                                <span>{k}</span>
                                            </div>
                                        ))}
                                    {/* <div className="stats-alpha">
                                        <strong>{data?.['weight_in_gms']}</strong>
                                        <span>Weight (Gms)</span>
                                    </div>
                                    <div className="stats-alpha">
                                        <strong>{data?.['max_power_consumption_in_watts']} (W)</strong>
                                        <span>Peak Power</span>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    ))}
                    <button className='add_new_device__btn' onClick={() => { set_add_new_device(sliderSelect.type) }}>Add Device</button>
                    {loading && <div className="d-flex w-100 align-items-center justify-content-center mt-5"><CircularProgress color='inherit' /> </div>}
                </>}
                {(navigator === 'Integrated' && add_new_device) &&
                    <div className='add__device__container'>
                        <AddDevice close={() => { set_add_new_device(false) }} add_new_device_details={add_new_device_details} />
                    </div>
                }
                {navigator === 'Discrete' && Object.keys((sliderSelect.type === 'ADCS' || sliderSelect.type === 'iADCS') ? adcsKeys : imuKeys).map(key => (
                    <div className="common-card-box" style={{ borderRadius: '5px' }} key={key} onClick={() => getAdcsDiscreteData(key)}>
                        <Accordion expanded={expanded === key} onChange={handleChange(key)}
                            sx={{
                                '&.transparent-mode': {
                                    backgroundColor: '#00000000',
                                    color: '#fff'
                                },
                                '&.MuiButtonBase-root': {
                                    backgroundColor: '#00000000',
                                    color: '#fff'
                                },
                                '&.MuiPaper-root': {
                                    backgroundColor: "#00000000",
                                    color: '#fff'
                                }
                            }}>
                            <AccordionSummary
                                expandIcon={<span className="badge"><i className="fe fe-edit fs-17"></i></span>}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                sx={{
                                    '&.transparent-mode': {
                                        backgroundColor: '#00000000',
                                        color: '#fff'
                                    },
                                    '&.MuiButtonBase-root': {
                                        backgroundColor: '#00000000',
                                        color: '#fff'
                                    },
                                    '&.MuiPaper-root': {
                                        backgroundColor: "#00000000",
                                        color: '#fff'
                                    }
                                }}
                            >
                                <div className="d-flex flex-column">
                                    <Typography sx={{ width: '100%', flexShrink: 0, fontWeight: 500, fontSize: '18px', lineHeight: '25px' }}>
                                        {key}
                                    </Typography>
                                    <Typography style={{ color: "#ddd" }} sx={{ fontSize: '14px', fontWeight: 300 }}>
                                        {Object.keys((sliderSelect.type === 'ADCS' || sliderSelect.type === 'iADCS') ? selectedDiscreteData : selectedIMUData).length > 0 && (sliderSelect.type === 'ADCS' || sliderSelect.type === 'iADCS') ? selectedDiscreteData?.[`${key}`]?.['name'] : selectedIMUData?.[`${key}`]?.['name']}
                                    </Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="slider-content" style={{ marginTop: '-30px' }}>
                                    {(Object.keys((sliderSelect.type === 'ADCS' || sliderSelect.type === 'iADCS') ? selectedDiscreteData : selectedIMUData).length > 0 && (selectedDiscreteData?.[`${key}`]?.['name'] || selectedIMUData?.[`${key}`]?.['name'])) && <>
                                        <h4 style={{ display: 'flex', marginBottom: '10px' }}>{isNewTemplate ? 'Selected Template' : 'Existing Component '}</h4>
                                        <div className={selectedDiscreteId === ((sliderSelect.type === 'ADCS' || sliderSelect.type === 'iADCS') ? selectedDiscreteData?.[`${key}`]?.['name'] : selectedIMUData?.[`${key}`]?.['name']) ? "common-card-box active" : "common-card-box"} style={{ width: '100%' }}
                                            onClick={() => {
                                                if (sliderSelect.type === 'ADCS' || sliderSelect.type === 'iADCS') {
                                                    setSelectedDiscreteData((previous) => ({
                                                        ...previous,
                                                        [key]: selectedDiscreteData?.[`${key}`]
                                                    }))
                                                    setIsSelectedDiscreteData(true)
                                                } else if (sliderSelect.type === 'IMU' || sliderSelect.type === 'iIMU') {
                                                    setSelectedIMUData((previous) => ({
                                                        ...previous,
                                                        [key]: selectedIMUData?.[`${key}`]
                                                    }))
                                                    setIsSelectedDiscreteData(true)
                                                }
                                                setSelectedDiscreteId((sliderSelect.type === 'ADCS' || sliderSelect.type === 'iADCS') ? selectedDiscreteData?.[`${key}`]?.['name'] : selectedIMUData?.[`${key}`]?.['name'])
                                                setExpanded('')
                                            }}>
                                            <div className="common-card-content">
                                                <div>
                                                    {(sliderSelect.type === 'ADCS' || sliderSelect.type === 'iADCS') ? selectedDiscreteData?.[`${key}`]?.['name'] : selectedIMUData?.[`${key}`]?.['name']}
                                                </div>
                                                {(((sliderSelect.type === 'ADCS' || sliderSelect.type === 'iADCS') && !isAdcsKeys) || ((sliderSelect.type === 'IMU' || sliderSelect.type === 'iIMU') && !isImuKeys)) && <div className="d-flex flex-wrap">
                                                    {Object.keys((sliderSelect.type === 'ADCS' || sliderSelect.type === 'iADCS') ? selectedDiscreteData?.[`${key}`]?.["product-cookie"] : selectedIMUData?.[`${key}`]?.["product-cookie"]).filter(
                                                        k => k !== 'friendly-name').map((k) => (
                                                            <div key={k} className="stats-alpha">
                                                                <strong>{(sliderSelect.type === 'ADCS' || sliderSelect.type === 'iADCS') ? selectedDiscreteData?.[`${key}`]?.["product-cookie"]?.[`${k}`] : selectedIMUData?.[`${key}`]?.["product-cookie"]?.[`${k}`]}</strong>
                                                                <span>{k}</span>
                                                            </div>
                                                        ))}
                                                    {/* <div className="stats-alpha">
                                                        <strong>{(sliderSelect.type === 'ADCS' || sliderSelect.type === 'iADCS') ? selectedDiscreteData?.[`${key}`]?.['weight_in_gms'] : selectedIMUData?.[`${key}`]?.['weight_in_gms']}</strong>
                                                        <span>Weight (Gms)</span>
                                                    </div>
                                                    <div className="stats-alpha">
                                                        <strong>{(sliderSelect.type === 'ADCS' || sliderSelect.type === 'iADCS') ? selectedDiscreteData?.[`${key}`]?.['max_power_consumption_in_watts'] : selectedDiscreteData?.[`${key}`]?.['max_power_consumption_in_watts']} (W)</strong>
                                                        <span>Peak Power</span>
                                                    </div> */}
                                                </div>}
                                            </div>
                                        </div>
                                    </>}
                                    <h5 style={{ display: 'flex', marginBottom: '10px' }}>{Object.keys(getDiscreteAdcsData).length === 0 && 'No '}Available Components </h5>
                                    {navigator === 'Discrete' && getDiscreteAdcsData.map(data => (
                                        <div key={data.name} className={selectedDiscreteId === data.name ? "common-card-box active" : "common-card-box"} style={{ width: '100%' }}
                                            onClick={() => {
                                                if (sliderSelect.type === 'ADCS' || sliderSelect.type === 'iADCS') {
                                                    setSelectedDiscreteData((previous) => ({
                                                        ...previous,
                                                        [key]: data
                                                    }))
                                                    setIsSelectedDiscreteData(true)
                                                } else if (sliderSelect.type === 'IMU' || sliderSelect.type === 'iIMU') {
                                                    setSelectedIMUData((previous) => ({
                                                        ...previous,
                                                        [key]: data

                                                    }))
                                                    setIsSelectedDiscreteData(true)
                                                }
                                                setSelectedDiscreteId(data.name)
                                                setExpanded('')
                                            }}>
                                            <div className="common-card-content">
                                                <div>
                                                    {data.name}
                                                </div>
                                                <div className="d-flex flex-wrap">
                                                    {Object.keys(data?.["product-cookie"]).filter(
                                                        k => k !== 'friendly-name').map((k) => (
                                                            <div key={k} className="stats-alpha">
                                                                <strong>{data["product-cookie"][k]}</strong>
                                                                <span>{k}</span>
                                                            </div>
                                                        ))}
                                                    {/* <div className="stats-alpha">
                                                        <strong>{data?.['weight_in_gms']}</strong>
                                                        <span>Weight (Gms)</span>
                                                    </div>
                                                    <div className="stats-alpha">
                                                        <strong>{data?.['max_power_consumption_in_watts']} (W)</strong>
                                                        <span>Peak Power</span>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    {loading && <div className="d-flex w-100 align-items-center justify-content-center mt-5"><CircularProgress color='inherit' /> </div>}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                ))}
            </div>
        </>}

        {(isCloneTemplate || isNewTemplate) && !(sliderSelect.type === 'Edge Device' || sliderSelect.type === 'Edge SSD' || sliderSelect.type === 'Payload') && (sliderSelect.type !== 'IMU' && sliderSelect.type !== 'iIMU' && sliderSelect.type !== 'ADCS' && sliderSelect.type !== 'iADCS') && <>
            <div className="slider-content">
                {(sliderSelect.type === 'Bus OBC' && (Object.keys(busOBCData && busOSData).length > 0)) && ['Bus OBC', 'Bus OS'].map(key => (
                    <div className="common-card-box" style={{ borderRadius: '5px' }} key={key}>
                        <Accordion expanded={expanded === key} onChange={handleChange(key)}
                            sx={{
                                '&.transparent-mode': {
                                    backgroundColor: '#00000000',
                                    color: '#fff'
                                },
                                '&.MuiButtonBase-root': {
                                    backgroundColor: '#00000000',
                                    color: '#fff'
                                },
                                '&.MuiPaper-root': {
                                    backgroundColor: "#00000000",
                                    color: '#fff'
                                }
                            }}>
                            <AccordionSummary
                                expandIcon={<span className="badge"><i className="fe fe-edit fs-17"></i></span>}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                sx={{
                                    '&.transparent-mode': {
                                        backgroundColor: '#00000000',
                                        color: '#fff'
                                    },
                                    '&.MuiButtonBase-root': {
                                        backgroundColor: '#00000000',
                                        color: '#fff'
                                    },
                                    '&.MuiPaper-root': {
                                        backgroundColor: "#00000000",
                                        color: '#fff'
                                    }
                                }}
                            >
                                <div className='d-flex w-100' onClick={() => { getOtherTemplateData(key) }}>
                                    <div className="d-flex flex-column">
                                        <Typography sx={{ width: '100%', flexShrink: 0, fontWeight: 500, fontSize: '18px', lineHeight: '25px' }}>
                                            {key}
                                        </Typography>
                                        <Typography style={{ color: "#ddd" }} sx={{ fontSize: '14px', fontWeight: 300 }}>
                                            {key === 'Bus OBC' ? busOBCData?.['name'] : busOSData?.['name']}
                                        </Typography>
                                    </div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                {!add_new_device &&
                                    <div className="slider-content" style={{ marginTop: '-30px' }}
                                        onClick={(e) => { e.stopPropagation() }}
                                    >
                                        {(busOBCData?.['name'] && busOSData?.['name']) && <>
                                            <h4 style={{ display: 'flex', marginBottom: '10px' }}>{isNewTemplate ? 'Selected Template' : 'Existing Component '} </h4>
                                            <div className={(((selectedIntegratedId === busOBCData?.['name']) && key === 'Bus OBC') ? busOBCData?.['name'] : busOSData?.['name']) ? 'common-card-box active' : "common-card-box"} style={{ width: '100%' }}
                                                onClick={() => {
                                                    if (key === 'Bus OBC') {
                                                        setBusOBCData(busOBCData)
                                                        setSelectedIntegratedId(busOBCData['name'])
                                                        setSelectedTemplateData((prev) => ({ ...prev, [key]: busOBCData }))
                                                    } else if (key === 'Bus OS') {
                                                        setBusOSData(busOSData)
                                                        setSelectedIntegratedId(busOSData['name'])
                                                        setSelectedTemplateData((prev) => ({ ...prev, [key]: busOSData }))
                                                    }
                                                    setExpanded('')
                                                }}>
                                                <div className="common-card-content">
                                                    <div>
                                                        {(sliderSelect.type === 'Bus OBC' && sliderSelect.type === key) ? busOBCData['name'] : busOSData?.['name']}
                                                    </div>
                                                    <div className="d-flex flex-wrap">
                                                        {Object.keys((sliderSelect.type === 'Bus OBC' && sliderSelect.type === key) ? busOBCData?.["product-cookie"] : busOSData?.["product-cookie"]).filter(
                                                            k => k !== 'friendly-name').map((k) => (
                                                                <div key={k} className="stats-alpha">
                                                                    <strong>{(sliderSelect.type === 'Bus OBC' && sliderSelect.type === key) ? busOBCData?.["product-cookie"][k] : busOSData?.["product-cookie"][k]}</strong>
                                                                    <span>{k}</span>
                                                                </div>
                                                            ))}
                                                        {/* {(sliderSelect.type === 'Bus OBC' && sliderSelect.type === key) && <div className="stats-alpha">
                                                        <strong>{(sliderSelect.type === 'Bus OBC' && sliderSelect.type === key) ? busOBCData?.["weight_in_gms"] : busOSData?.["weight_in_gms"]}</strong>
                                                        <span>Weight (Gms)</span>
                                                    </div>}
                                                    {(sliderSelect.type === 'Bus OBC' && sliderSelect.type === key) && <div className="stats-alpha">
                                                        <strong>{(sliderSelect.type === 'Bus OBC' && sliderSelect.type === key) ? busOBCData?.['max_power_consumption_in_watts'] : busOSData?.["max_power_consumption_in_watts"] }</strong>
                                                        <span>Peak Power (W)</span>
                                                    </div>} */}
                                                    </div>
                                                </div>
                                            </div>
                                        </>}
                                        <h4 style={{ display: 'flex', marginBottom: '10px' }}>{getIntegratedData && Object.keys(getIntegratedData).length === 0 && 'No '}Available Components </h4>
                                        {getIntegratedData.map((data, i) => (
                                            <div key={i} className={selectedIntegratedId === data.name ? 'common-card-box active' : "common-card-box"} style={{ width: '100%' }}
                                                onClick={() => {
                                                    if (key === 'Bus OBC') {
                                                        setBusOBCData(data)
                                                        setSelectedIntegratedId(data.name)
                                                        setSelectedTemplateData((prev) => ({ ...prev, [key]: data }))
                                                    } else if (key === 'Bus OS') {
                                                        setBusOSData(data)
                                                        setSelectedIntegratedId(data.name)
                                                        setSelectedTemplateData((prev) => ({ ...prev, [key]: data }))
                                                    }
                                                    setExpanded('')
                                                }}>
                                                <div className="common-card-content">
                                                    <div>
                                                        {data.name}
                                                    </div>
                                                    <div className="d-flex flex-wrap">
                                                        {Object.keys(data?.["product-cookie"]).filter(
                                                            k => k !== 'friendly-name').map((k) => (
                                                                <div key={k} className="stats-alpha">
                                                                    <strong>{data["product-cookie"][k]}</strong>
                                                                    <span>{k}</span>
                                                                </div>
                                                            ))}
                                                        {/* {(sliderSelect.type === 'Bus OBC' && sliderSelect.type === key) && <div className="stats-alpha">
                                                        <strong>{data?.['weight_in_gms']}</strong>
                                                        <span>Weight (Gms)</span>
                                                    </div>}
                                                    {(sliderSelect.type === 'Bus OBC' && sliderSelect.type === key) && <div className="stats-alpha">
                                                        <strong>{data?.['max_power_consumption_in_watts']} (W)</strong>
                                                        <span>Peak Power</span>
                                                    </div>} */}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <button className='add_new_device__btn' onClick={() => { set_add_new_device(sliderSelect.type) }}>Add New Device</button>
                                        {loading && <div className="d-flex w-100 align-items-center justify-content-center mt-5"><CircularProgress color='inherit' /> </div>}
                                    </div>
                                }
                                {add_new_device &&
                                    <AddDevice close={() => { set_add_new_device(false) }} add_new_device_details={add_new_device_details} />
                                }
                            </AccordionDetails>
                        </Accordion>
                    </div>))
                }
                {sliderSelect.type !== 'Bus OBC' && <>
                    {!add_new_device &&
                        <Fragment>
                            {sliderSelect?.['data']?.['name'] && <>
                                <h4 style={{ display: 'flex', marginBottom: '10px' }}>{isNewTemplate ? 'Selected Template' : 'Existing Component '} </h4>
                                <div className={selectedIntegratedId === sliderSelect['data']['name'] ? 'common-card-box active' : "common-card-box"}
                                    onClick={() => {
                                        setSelectedIntegratedId(sliderSelect['data']['name'])
                                        setSelectedTemplateData({ [sliderSelect.type]: sliderSelect['data'] })
                                    }}>
                                    <div className="common-card-content">
                                        <div>
                                            {sliderSelect['data']['name']}
                                        </div>
                                        <div className="d-flex flex-wrap">
                                            {Object.keys(sliderSelect['data']?.["product-cookie"]).filter(
                                                k => k !== 'friendly-name').map((k) => (
                                                    <div key={k} className="stats-alpha">
                                                        <strong>{sliderSelect['data']["product-cookie"][k]}</strong>
                                                        <span>{k}</span>
                                                    </div>
                                                ))}
                                            {/* <div className="stats-alpha">
                                        <strong>{sliderSelect['data']?.['weight_in_gms']}</strong>
                                        <span>Weight (Gms)</span>
                                    </div>
                                    <div className="stats-alpha">
                                        <strong>{sliderSelect['data']?.['max_power_consumption_in_watts']} (W)</strong>
                                        <span>Peak Power</span>
                                    </div> */}
                                        </div>
                                    </div>
                                </div>
                            </>}
                            <h4 style={{ display: 'flex', marginBottom: '10px' }}>{Object.keys(getIntegratedData)?.length === 0 && 'No '}Available Components </h4>
                            {getIntegratedData.map((data, i) => (
                                <div key={i} className={selectedIntegratedId === data.name ? 'common-card-box active' : "common-card-box"}
                                    onClick={() => {
                                        setSelectedIntegratedId(data.name)
                                        setSelectedTemplateData({ [sliderSelect.type]: data })
                                    }}>
                                    <div className="common-card-content">
                                        <div>
                                            {data.name}
                                        </div>
                                        <div className="d-flex flex-wrap">
                                            {Object.keys(data?.["product-cookie"]).filter(
                                                k => k !== 'friendly-name').map((k) => (
                                                    <div key={k} className="stats-alpha">
                                                        <strong>{data["product-cookie"][k]}</strong>
                                                        <span>{k}</span>
                                                    </div>
                                                ))}
                                            {/* <div className="stats-alpha">
                                        <strong>{data?.['weight_in_gms']}</strong>
                                        <span>Weight (Gms)</span>
                                    </div>
                                    <div className="stats-alpha">
                                        <strong>{data?.['max_power_consumption_in_watts']} (W)</strong>
                                        <span>Peak Power</span>
                                    </div> */}
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <button className='add_new_device__btn' onClick={() => { set_add_new_device(sliderSelect.type) }}>Add New Device</button>
                        </Fragment>
                    }
                    {add_new_device &&
                        <div className='add__device__container'>
                            <AddDevice close={() => { set_add_new_device(false) }} add_new_device_details={add_new_device_details} />
                        </div>
                    }
                    {loading && <div className="d-flex w-100 align-items-center justify-content-center mt-5"><CircularProgress color='inherit' /> </div>}
                </>}
            </div>
        </>}

        {(!add_new_device && (isNewTemplate || isCloneTemplate) && (sliderSelect.type === 'Solar Panel' || sliderSelect.type === 'Payload SSD' || sliderSelect.type === 'Temperature Sensor' || sliderSelect.type === 'Heater' || sliderSelect.type === 'Thruster')) &&
            <div className="slider-footer">
                <Button
                    variant="primary"
                    type="button"
                    disabled={totalCount === 1}
                    className="btn btn-primary counter-btn"
                    onClick={() => setTotalCount((prevState) => {
                        if (isCountBtnDisabled) {
                            setIsCountBtnDisabled(false)
                        }

                        if (prevState > 1 && totalCount > 1) {
                            return prevState - 1;
                        } else {
                            return 1;
                        }
                    })}
                >
                    <i className="fe fe-minus"></i>
                </Button>

                {totalCount}

                <Button
                    variant="primary"
                    type="button"
                    disabled={isCountBtnDisabled}
                    className="btn btn-primary counter-btn"
                    onClick={() => {
                        if (sliderSelect.type !== 'Solar Panel') {
                            setTotalCount((prevState) => prevState + 1)
                        } else if (sliderSelect.type === 'Solar Panel' && totalCount + 1 <= 5) {
                            setTotalCount((prevState) => prevState + 1)
                        } else if (sliderSelect.type === 'Solar Panel' && totalCount === 5) {
                            setIsCountBtnDisabled(true)
                        }
                    }}
                >
                    <i className="fe fe-plus"></i>
                </Button>
            </div>}
        {/* ///////////////////................................////////////////////// */}

        {/* ////...........................................................//// */}

        <div className="slider-footer">
            {(isCloneTemplate || isNewTemplate) && !(sliderSelect.type === 'Edge Device' || sliderSelect.type === 'Edge SSD' || sliderSelect.type === 'Payload') && <Button
                variant="primary"
                type="button"
                disabled={add_new_device}
                className="btn btn-outline-danger pt-1 pb-1 pe-7 ps-7 ms-5 fs-14"
                onClick={handleCancel}
            >
                Cancel
            </Button>}
            <Button
                variant="primary"
                disabled={add_new_device}
                type="button"
                className="btn btn-primary pt-1 pb-1 pe-7 ps-7 ms-5 fs-14"
                onClick={handleSubmitCloneTemplate}
            >
                {(isCloneTemplate || isNewTemplate) && !(sliderSelect.type === 'Edge Device' || sliderSelect.type === 'Edge SSD' || sliderSelect.type === 'Payload') ? "Select" : "Close"}
            </Button>
        </div>
    </div>;
};
export default BusSlider;
