import CustomToolTip from '../../CommonComponents/CustomToolTip'
import { UploadSvg } from '../components/center/planning/Svgs'

const UploadScheduleButton = () => {
    return (
        <CustomToolTip title='Upload Schedule' placement='bottom'>
            <button>
                <UploadSvg />
            </button>
        </CustomToolTip>
    )
}

export default UploadScheduleButton