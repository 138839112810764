import { Fragment } from 'react'
import useUrlParams from '../../../../hooks/useUrlParams';
import { get_formated_time } from '../../../../utils';
import { ButtonSvg } from './Svgs';
import useTimeline from '../../../../hooks/useTimeline';
import useViewer from '../../../../hooks/useViewer';
import useScenario from '../../../../hooks/useScenario';


const ContactTimeFrame = () => {
    const { dashboard } = useUrlParams()
    const { simulation_time, scenario } = useScenario();
    const { change_viewer_time, current_simulation_time } = useViewer()
    const { timelist, window_width, timeframe_details, change_frame } = useTimeline()
    const simulation_start_time = simulation_time?.start_time
    const simulation_end_time = simulation_time?.end_time
    const simulation_duration = simulation_time.duration
    const { frame_start_time, frame_end_time, frame_duration } = timeframe_details
    const per_sec_pixel = window_width / frame_duration
    const current_time = current_simulation_time / 1000
    const marker_position = (current_time - frame_start_time) * per_sec_pixel
    const marker_inframe = (current_time >= frame_start_time && current_time <= frame_end_time) ? true : false
    const simulation_time_highlighter__width = simulation_duration * per_sec_pixel
    const simulation_time_highlighter__left = (simulation_start_time - frame_start_time) * per_sec_pixel

    return (
        <div className="contact__timeframe__container" style={{ width: `${window_width}px` }}>
            <div className='timeframe__datetime__container'>
                {timelist.map(date_time => (
                    <div className='d-flex align-items-center gap-1' key={date_time.epoch}>
                        <div className="contact__timeframe__date">{date_time.date}</div>
                        <div className="contact__timeframe__time">{date_time.time}</div>
                    </div>
                ))}
            </div>

            {marker_inframe &&
                <Fragment>
                    <span className="current__time__indicator" style={{ left: `${marker_position}px` }} />
                    <span style={{
                        right: (marker_position + 100) > window_width ? '0px' : 'none',
                        left: (marker_position - 100) < 0 ? '0px' : (marker_position + 100) > window_width ? 'none' : `${marker_position - 100}px`,
                    }}
                        className="indicator__tooltip">{`${get_formated_time(current_time)}`}</span>
                </Fragment>
            }
            {current_time &&
                <input
                    disabled={dashboard !== 'summary'}
                    type="range"
                    step='any'
                    min={frame_start_time!}
                    max={frame_end_time!}
                    value={current_time}
                    onChange={change_viewer_time}
                    className={`timeframe__slider__input`}
                />
            }
            <div className="timeframe__datetime__indicators">
                <Fragment>
                    {[1, 2, 3].map(elem => (
                        <div key={elem} className="timeframe__datetime__indicator" >
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
                                <path d="M10.7929 0.75H1.20711C0.761654 0.75 0.538571 1.28857 0.853554 1.60355L5.64645 6.39645C5.84171 6.59171 6.15829 6.59171 6.35355 6.39645L11.1464 1.60355C11.4614 1.28857 11.2383 0.75 10.7929 0.75Z" fill="#F0F0F0" />
                            </svg>
                        </div>
                    ))}
                    <div className='simulation__time__highlight'
                        style={{
                            width: `${simulation_time_highlighter__width}px`,
                            left: `${simulation_time_highlighter__left}px`,
                        }}
                    >
                        <div className='simulation__time__highlight__start__point'>
                            <div className='simulation__time__highlight__tooltip' style={{ left: '0px' }}>
                                {get_formated_time(simulation_start_time)}
                            </div>
                        </div>
                        <div className='simulation__time__highlight__end__point'>
                            <div className='simulation__time__highlight__tooltip' style={{ right: '0px' }}>
                                {get_formated_time(simulation_end_time)}
                            </div>
                        </div>
                    </div>
                </Fragment>
            </div>
            {['left', 'right'].map(elem => {
                return (
                    <button
                        key={elem}
                        className={`timeframe__change__button 
                        ${elem} 
                        ${elem === 'left' && ((simulation_start_time >= frame_start_time) && dashboard !== 'operate') && 'hide__button'} 
                        ${elem === 'left' && ((scenario?.['scenarioStartTime'] >= frame_start_time) && dashboard === 'operate') && 'hide__button'} 
                        ${(elem === 'right' && (simulation_end_time <= frame_end_time) && dashboard !== 'operate') && 'hide__button'}`}
                        onClick={() => { change_frame(elem, current_time) }}
                    >
                        <ButtonSvg />
                    </button>
                )
            })}
        </div>
    )
}

export default ContactTimeFrame