import { useState } from "react"
import './AddDevice.css'
import { Form } from "react-bootstrap"
import CustomToolTip from "../../../../CommonComponents/CustomToolTip";

interface Props {
    close: () => void;
    add_new_device_details: (data: any) => void;
}


const AddDevice = ({ close, add_new_device_details }: Props) => {
    const [validate, setValidate] = useState(false)

    const [deviceName, setDeviceName] = useState('')
    const [deviceDetails, setDeviceDetails] = useState<{ key: string, value: string }[]>([])

    const add_device_details = () => {
        const details: { key: string, value: string }[] = [...deviceDetails, { key: '', value: '' }]
        setDeviceDetails(details)
    }

    const check_empty_fields = () => {
        let validate: boolean = false
        deviceDetails.map(device => {
            if (!device.key || !device.value) {
                validate = true
            }
        })
        return validate
    }
    const validate_date = () => {
        let data: any = {
            name: '',
            'product-cookie': {}
        }
        let validate: boolean = false
        if (!deviceName) {
            setValidate(true)
            validate = false
            return
        } else {
            data['name'] = deviceName
            deviceDetails.map(device => {
                if (!device.key || !device.value) {
                    validate = false
                    setValidate(true)
                    return
                } else {
                    data['product-cookie'][device.key] = device.value
                    validate = true
                }
            })
        }

        return { data, validate }
    }

    const handle_submit = () => {
        const result = validate_date()
        if (result?.validate && result?.data) {
            const { data } = result

            add_new_device_details(data)
        }
    }


    return (
        <div className="d-flex w-100 flex-column gap-3 cursor-auto">
            <Form validated={validate} className="d-flex w-100 flex-column gap-1">
                <span>Name</span>
                <Form.Control
                    required
                    className="device__input"
                    type="text"
                    placeholder="Enter your device name"
                    value={deviceName}
                    onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                            e.preventDefault();
                        }
                    }}
                    onChange={(e) => {
                        e.preventDefault()
                        setDeviceName(e.target.value)
                    }}
                />
                <Form.Control.Feedback type="invalid"> Enter your device name </Form.Control.Feedback>
            </Form>
            {deviceDetails.map((item, index) => {
                return (
                    <Form key={index} validated={validate} className="d-flex w-100 gap-2 align-items-center">
                        <div className='w-90 gap-2 d-flex align-items-center'>
                            <div className="d-flex flex-column gap-1 w-50">
                                <Form.Control
                                    required
                                    className="device__input"
                                    type="text"
                                    placeholder="Enter your key"
                                    value={item.key}
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 13) {
                                            e.preventDefault();
                                        }
                                    }}
                                    onChange={(e) => {
                                        e.stopPropagation()
                                        e.preventDefault()
                                        const details = [...deviceDetails]
                                        details[index].key = e.target.value
                                        setDeviceDetails(details)
                                    }}
                                />
                                <Form.Control.Feedback type="invalid"> Please Enter your Key </Form.Control.Feedback>
                            </div>
                            <div className="d-flex flex-column gap-1 w-50">
                                <Form.Control
                                    required
                                    type="text"
                                    className="device__input"
                                    placeholder="Enter your value"
                                    value={item.value}
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 13) {
                                            e.preventDefault();
                                        }
                                    }}
                                    onChange={(e) => {
                                        e.preventDefault()
                                        const details = [...deviceDetails]
                                        details[index].value = e.target.value
                                        setDeviceDetails(details)
                                    }}
                                />
                                <Form.Control.Feedback type="invalid"> Please Enter your Key </Form.Control.Feedback>
                            </div>
                        </div>
                        <CustomToolTip title="Delete Details" placement="top">
                            <button
                                onClick={() => {
                                    const details = [...deviceDetails]
                                    details.splice(index, 1)
                                    setDeviceDetails(details)
                                }}
                                className="delete__details__btn">
                                <i className="fa fa-trash "></i>
                            </button>
                        </CustomToolTip>
                    </Form>
                )
            })}
            <div className="d-flex w-100 justify-content-between gap-3 mt-3">
                <button className="cancel__button" onClick={close}>Cancel</button>
                <div className="d-flex gap-3">
                    <button className="add__more__btn" disabled={!deviceName || check_empty_fields()} onClick={add_device_details}>Add more</button>
                    <button className="save__btn" disabled={!deviceName || check_empty_fields() || deviceDetails.length === 0} onClick={handle_submit}>Save</button>
                </div>
            </div>
        </div>
    )
}

export default AddDevice