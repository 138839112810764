import { useContext } from 'react';
import { ViewerContext } from '../providers/CesiumProvider';
import * as Cesium from "cesium";
import useScenario from './useScenario';
import { update_sat_orbit_path } from './cesiumUtils';

const useViewer = () => {
    const context = useContext(ViewerContext);
    if (context === undefined) {
        throw new Error('useViewer must be used within a ViewerProvider');
    }
    const { viewer, set_current_simulation_time } = context;
    const { simulation_time } = useScenario()

    const { start_time, end_time } = simulation_time
    const simulation_start_time = start_time * 1000
    const simulation_end_time = end_time * 1000

    const change_viewer_time = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = Number(event.target.value);

        if (viewer) {
            const julianDate = Cesium.JulianDate.fromDate(new Date(newValue * 1000));
            const current_time = Cesium.JulianDate.toDate(julianDate).getTime();
            if (current_time >= simulation_start_time && current_time <= simulation_end_time) {
                viewer.clock.currentTime = julianDate;
                set_current_simulation_time(current_time)
                update_sat_orbit_path(viewer, current_time)
            }
        }

    };

    return {
        ...context,
        change_viewer_time
    }
};

export default useViewer;