import { FC } from "react"
import * as Cesium from "cesium"
import './GroundTargetCard.css'
import { truncateNumber } from "./CommonFunctions";
import { LatLongSvg } from "./svgs/Svgs";

interface GsProps {
    target: any;
    viewer: any
}

const GroundTargetCard: FC<GsProps> = ({ target, viewer }) => {

    if (target && viewer) {
        let targetName = target?.name ?? target?.groundTargetName;
        let targetLong = target?.longitude ?? target.position[0];
        let targetLat = target?.latitude ?? target.position[1];

        var cartographic = Cesium.Cartographic.fromDegrees(targetLong, targetLat);
        var cartesianPosition = Cesium.Cartographic.toCartesian(cartographic);
        const popupPosition = Cesium.SceneTransforms.wgs84ToWindowCoordinates(
            viewer.scene,
            cartesianPosition
        );

        let outside_window_x = (popupPosition.x + (370)) > window.innerWidth;
        let top = `${popupPosition.y}px`;
        let left = outside_window_x ? `${popupPosition.x - (370)}px` : `${popupPosition.x + 10}px`;

        return (
            <div className="popup-modal"
                key={target?.['groundTargetId']}
                style={{
                    display: 'flex',
                    flexDirection: "row",
                    alignContent: 'center',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                    left: left,
                    top: top,
                    transition: 'all 0.3s ease',
                    padding: '12px',
                    gap: '16px',
                    width: '300px',
                    height: '40px'
                }}>
                <div style={{ display: 'flex', flexDirection: 'column', }}>
                    <span title={targetName}
                        style={{
                            color: 'rgba(204, 245, 78, 1.00)', fontSize: '14px', letterSpacing: '0.56px',
                            maxWidth: '160px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}>
                        {targetName}
                    </span>
                </div>

                <div style={{ display: 'flex', alignItems: 'center', maxWidth: '200px' }}>
                    <div className="labelClass">
                        <span style={{ color: '#EEE', fontSize: '10px', opacity: '0.6' }}>
                            <LatLongSvg />
                        </span>
                        <span style={{ color: '#F0F0F0', fontSize: '12px', letterSpacing: '0.56px', marginLeft: '4px' }}>
                            {truncateNumber(targetLat, 2)}, {' '} {truncateNumber(targetLat, 2)}
                        </span>
                    </div>
                </div>
            </div >
        )


    }
    return null
}

export default GroundTargetCard