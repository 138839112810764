import { Fragment } from 'react'
import CustomToolTip from '../../../CommonComponents/CustomToolTip'
import useModals from '../../hooks/useModals';
import { ActivitySvg, ConsoleSvg, FilesQueueSvg, GroundScheduleSvg, NotificationSvg, OnboardScheduleSvg, PinnedSvg, TelemetrySvg } from './Svgs';
import useUrlParams from '../../hooks/useUrlParams';

const RightContainer = () => {
    const { dashboard } = useUrlParams()
    const { handleUpdateUrl, getModalDetails } = useModals()
    const toggle_modal = (modal: string) => {
        const modal_details = getModalDetails(modal)
        if (modal_details.open && modal_details.pinned) {
            handleUpdateUrl(modal, 'pinned')
        }
        else if (modal_details.open && !modal_details.pinned) {
            handleUpdateUrl(modal, 'close')
        } else {
            handleUpdateUrl(modal, 'open')
        }
    }

    return (
        <Fragment>
            <div className={`right__button__container`} onClick={(e: any) => { e.stopPropagation() }}>
                <CustomToolTip title='Onboard Schedule' placement='bottom'>
                    <button className={`right__section__button ${dashboard === 'quick-deploy' && 'hide__btn'} ${dashboard === 'operate-0.1' ? 'left__btn' : 'single__btn'} ${getModalDetails('onboard-schedule').open && 'active'}`} onClick={() => { toggle_modal('onboard-schedule') }}>
                        <OnboardScheduleSvg />
                        <PinnedSvg showPinned={getModalDetails('onboard-schedule').pinned} />
                    </button>
                </CustomToolTip>
                <CustomToolTip title='Ground Schedule' placement='bottom'>
                    <button className={`right__section__button ${dashboard === 'operate-0.1' ? 'right__btn' : 'hide__btn'} ${getModalDetails('ground-schedule').open && 'active'}`} onClick={() => { toggle_modal('ground-schedule') }}>
                        <GroundScheduleSvg />
                        <PinnedSvg showPinned={getModalDetails('ground-schedule').pinned} />
                    </button>
                </CustomToolTip>
            </div>
            <div className='right__button__container' onClick={(e: any) => { e.stopPropagation() }}>
                <CustomToolTip title='Console' placement='bottom'>
                    <button className={`right__section__button left__btn ${getModalDetails('console').open && 'active'}`} onClick={() => { toggle_modal('console') }}>
                        <ConsoleSvg />
                        <PinnedSvg showPinned={getModalDetails('console').pinned} />
                    </button>
                </CustomToolTip>
                <CustomToolTip title='Telemetry' placement='bottom'>
                    <button className={`right__section__button center__btn ${getModalDetails('telemetry').open && 'active'}`} onClick={() => { toggle_modal('telemetry') }}>
                        <TelemetrySvg />
                        <PinnedSvg showPinned={getModalDetails('telemetry').pinned} />
                    </button>
                </CustomToolTip>
                <CustomToolTip title='File Queue' placement='bottom'>
                    <button className={`right__section__button right__btn ${getModalDetails('filequeue').open && 'active'}`} onClick={() => { toggle_modal('filequeue') }}>
                        <FilesQueueSvg />
                        <PinnedSvg showPinned={getModalDetails('filequeue').pinned} />
                    </button>
                </CustomToolTip>
            </div>
            <div className={`right__button__container ${dashboard !== 'operate-0.1' && 'hide__section'}`} onClick={(e: any) => { e.stopPropagation() }}>
                <CustomToolTip title='Activity' placement='bottom'>
                    <button className={`right__section__button left__btn disabled`}>
                        <ActivitySvg />
                    </button>
                </CustomToolTip>
                <CustomToolTip title='Notification' placement='bottom'>
                    <button className={`right__section__button right__btn disabled`}>
                        <NotificationSvg />
                    </button>
                </CustomToolTip>
            </div>
        </Fragment>
    )
}

export default RightContainer